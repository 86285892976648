/* eslint-disable @typescript-eslint/no-floating-promises */
import {Box, Stack, Typography, debounce} from '@mui/material';
import {BirthSex} from '@src/api/account';
import {PulseLoader} from '@src/components/PulseLoader';
import {Tooltip} from '@src/components/Tooltip';
import {WBox} from '@src/components/WhiteBox';
import {DateControl} from '@src/components/form/DateControl';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as Question} from '@src/shared/assets/icons/question.svg';
import {DEFAULT_AGE, DEFAULT_HEIGHT, DEFAULT_WEIGHT, weightImages} from '@src/shared/constants/BMIconstants';
import {useMQuery} from '@src/shared/hooks';
import {dateFormatted} from '@src/shared/utils';
import {getBmi, getWeightTitle} from '@src/shared/utils/bmiUtils';
import {useAccount, useAccountForm} from '@src/store/account/hooks';
import {useRecomendations, useCreateBmi, useSteps} from '@src/store/bmi/hooks';
import {DateTime} from 'luxon';
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Button} from 'ui-kit';
import {checkIsPeroson18OrOlder} from '@src/pages/Connections/fragments/Relations/helpers/checkIsPeroson18OrOlder';
import {TooltipContent} from '../TooltipContent';
import {useAppDispatch} from '@src/store';
import {notifyActions} from '@src/store/notifications/slice';
import {envs} from '@src/shared/constants/envs';

import {CalculatorModals} from './components/CalculatorModals';
import {SexPicker} from './components/SexPicker';
import {SliderInput} from './components/Slider';
import {debounceTime} from './constants';
import {getSex} from './helpers';
import {sx} from './styles';
import {CalculatorProps} from './types';

export const Calculator: FC<CalculatorProps> = ({weight, height, isFetching, setHeight, setWeight, data}) => {
  const {mobile} = useMQuery();
  const {account, fetching: accountFetching} = useAccount();
  const {update} = useAccountForm();
  const {getRecommendations} = useRecomendations();
  const dispatch = useAppDispatch();
  const {step, setStep} = useSteps();

  const [searchParams] = useSearchParams();
  const urlBirthsex = searchParams.get('birthsex') as BirthSex;
  const urlHeight = searchParams.get('height');
  const urlWeight = searchParams.get('weight');
  const urlAge = searchParams.get('age');

  const {t} = useTranslate('journal');

  const [dateOfBirth, setDateOfBirth] = useState<string | undefined>();
  const [age, setAge] = useState(DEFAULT_AGE);
  const [isChanged, setIsChanged] = useState(!!data?.length);

  const [sex, setSex] = useState<BirthSex>(BirthSex.Unknown);
  const saveBmi = useCreateBmi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetRecommendations = useCallback(debounce(getRecommendations, debounceTime), []);

  const saveHandler = () => {
    if (!account?.birthDate && dateOfBirth) {
      update({
        data: {
          birthDate: {
            value: dateOfBirth,
          },
          birthSex: {
            value: sex !== BirthSex.Unknown ? sex : BirthSex.Male,
          },
        },
      });
    };
    if (account?.birthDate && account?.birthSex === BirthSex.Unknown) {
      update({
        data: {
          birthDate: {
            value: account?.birthDate,
          },
          birthSex: {
            value: sex !== BirthSex.Unknown ? sex : BirthSex.Male,
          },
        },
      });
    }
    saveBmi({height, weight});
  };

  const sexChangeHandler = useCallback((value: BirthSex) => {
    setSex(value);
  }, []);

  useEffect(() => {
    const birthDate = dateOfBirth || account?.birthDate;
    if (age && sex && birthDate) {
      debouncedGetRecommendations({
        birthDate,
        birthSex: sex === BirthSex.Unknown ? BirthSex.Male : sex,
        bmi: getBmi(weight, height),
      });
    };
  }, [account?.birthDate, age, dateOfBirth, height, sex, weight, debouncedGetRecommendations]);

  useEffect(() => {
    const birthDate = account?.birthDate;
    let accountAge;
    if (birthDate) {
      accountAge = Math.floor(Math.abs(DateTime.fromFormat(birthDate, 'yyyy-MM-dd').diffNow().as('years')));
    };

    const newSex = getSex(account?.birthSex, urlBirthsex);
    const newAge = (urlAge && parseInt(urlAge)) || accountAge || DEFAULT_AGE;
    setSex(newSex);
    setAge(newAge);
  }, [urlBirthsex, account, urlWeight, urlHeight, urlAge]);

  useEffect(() => {
    const newWidth = (urlWeight && parseFloat(urlWeight)) || DEFAULT_WEIGHT;
    const newHeight = (urlHeight && parseFloat(urlHeight)) || DEFAULT_HEIGHT;
    setWeight(newWidth);
    setHeight(newHeight);
  }, [setHeight, setWeight, urlHeight, urlWeight]);

  const weightResult = useMemo(() => {
    return getWeightTitle(weight, height);
  }, [weight, height]);

  const isDisabledSave = ((!dateOfBirth && !account?.birthDate) || !checkIsPeroson18OrOlder(dateOfBirth || account?.birthDate)) || !isChanged;

  const dateBirthHandler = useCallback((e: {
    target: {
      name: string
      value: string | [string, string] | undefined
    }
  }) => {
    setIsChanged(true);
    setDateOfBirth(e.target.value as string);
  }, []);

  useEffect(() => {
    if (step === 'error') {
      dispatch(
        notifyActions.showNotifications([
          {
            id: '',
            type: 'error',
            text: t('SMTH_WENT_WRONG', { link: envs.PATIENT_CONTACT_SUPPORT_URL }),
          },
        ])
      );
      setStep('init');
    }
  }, [step, dispatch, t]);

  useEffect(() => {
    setIsChanged(!!data?.length);
  }, [data]);

  return (
    <>
      <WBox sx={sx.mainBlock}>
        {(isFetching || accountFetching) && <Box sx={sx.fetchingBlock}><PulseLoader loading={isFetching} /></Box>}
        {!isFetching && !accountFetching && (
          <>
            <Box sx={sx.topContainer}>
              <Typography sx={sx.title}>{t('CALCULATOR')}</Typography>
              <Tooltip sx={sx.tooltip} title={<TooltipContent />} placement={'bottom-start'}>
                <Box sx={{zIndex: 5}}>
                  <Question />
                </Box>
              </Tooltip>
            </Box>
            <Box sx={sx.calculatorConatiner}>
              <Stack sx={sx.controls}>
                <Typography sx={sx.text}>{t('YOU_CAN_FIND_WHEATHER_YOU_')}</Typography>
                <SexPicker
                  sx={sx.sexPicker}
                  onChange={sexChangeHandler}
                  sex={sex}
                  defaultSex={account?.birthSex} />
                <SliderInput
                  sx={sx.slider}
                  onChange={(e, value) => {
                    setIsChanged(true);
                    setHeight(value as number);
                  }}
                  value={height}
                  min={12}
                  step={1}
                  max={108}
                  id="height"
                  label={t('HEIGHT_FT')}
                  valueLabelDisplay='auto'
                  valueLabelFormat={(x) => {
                    const inch = x % 12;
                    const foot = Math.floor(x / 12);
                    return `${foot}'${inch ? `${inch}"` : ''}`;
                  }} />
                <SliderInput
                  sx={sx.slider}
                  onChange={(e, value) => {
                    setIsChanged(true);
                    setWeight(value as number);
                  }}
                  value={weight}
                  max={1323}
                  min={1}
                  id="weight"
                  label={t('WEIGHT_LB')}
                  valueLabelDisplay='auto'
                />
                {account?.birthDate && (
                  <SliderInput
                    sx={sx.sliderDisabled}
                    value={age}
                    max={130}
                    min={0}
                    sliderSx={sx.sliderAge}
                    id="age"
                    label={t('AGE_YARS')}
                    valueLabelDisplay='auto'
                    valueLabelFormat={() => t('YOU_CAN_CHANGE_YOUR_DATE_')}
                    isDisplayedValue={true}
                  />
                )}
                {!account?.birthDate && (
                  <Stack sx={{width: 302}}>
                    <Typography variant='14_18_700'>
                      {t('DATE_OF_BIRTH')}
                    </Typography>
                    <DateControl
                      disabledFuture
                      name="birthDate"
                      placeholder='mm/dd/yyyy'
                      value={dateFormatted(dateOfBirth) || undefined}
                      onChange={dateBirthHandler}
                      error={!!dateOfBirth && !checkIsPeroson18OrOlder(dateOfBirth) ? t("ENTER_A_VALID_DATE_OF_BIRTH") : ""}
                      label={''}
                      errorTextStyles={{
                        whiteSpace: 'break-spaces',
                      }}
                    />
                  </Stack>
                )}
                <Box sx={sx.buttonBox}>
                  <Button
                    onClick={saveHandler}
                    fullWidth={mobile}
                    disabled={isDisabledSave}>
                    {t('SAVE')}
                  </Button>
                </Box>
              </Stack>
              {!mobile && (
                <Box sx={sx.imageBox}>
                  {!isChanged || !weight ? weightImages[sex].default : weightImages[sex][weightResult]}
                </Box>
              )}
            </Box>
          </>
        )}
      </WBox>
      <CalculatorModals/>
    </>
  );
};
