/* eslint-disable no-comments/disallowComments */
/** @jsxImportSource @emotion/react */
import {css as _} from '@mui/material';
import {DateTime} from 'luxon';
import {FC, useCallback, useState} from 'react';

import {BodyContainer} from './fragments/BodyContainer';
import {DatesArea} from './fragments/DatesArea';
import {Header, THeaderActionType} from './fragments/Header';
import {MonthsArea} from './fragments/MonthsArea';
import {YearArea} from './fragments/YearArea';
import {ICalendarProps} from './types';

export const Calendar: FC<ICalendarProps> = ({
  onChange,
  date,
  range,
  order = 0,
  className,
  mode,
  monthDate,
  onChangeMonthDate,
  disabledPast,
  onlyFutureYears,
  disabledFuture,
  isRangeMode,
  reverseYears,
  disableCurrentDay,
}) => {
  const [selectorType, setSelectorType] = useState<'day' | 'month' | 'year'>(
    'day',
  );
  const handleClick = useCallback(
    (d: DateTime) => {
      if (mode === 'range') {
        onChange?.(d, order);
      } else {
        window.setTimeout(() => {
          onChange?.(d, order);
        }, 100);
      }
    },
    [mode, onChange, order],
  );
  const handleForward = () => {
    const date = monthDate.plus(
      selectorType === 'day' ? {month: 1} : {year: 1},
    );
    onChangeMonthDate(date, order);
  };
  const handleBack = () => {
    const date = monthDate.minus(
      selectorType === 'day' ? {month: 1} : {year: 1},
    );
    onChangeMonthDate(date, order);
  };

  const handleHeaderActions = ({type}: { type: THeaderActionType }) => {
    switch (type) {
      case 'year':
      case 'month':
      case 'day':
        setSelectorType(type);
        break;
      case 'back':
        handleBack();
        break;
      case 'forward':
        handleForward();
        break;
    }
  };
  return (
    <BodyContainer className={className}>
      <Header
        type={selectorType}
        date={monthDate}
        onClick={handleHeaderActions}
        isRangeMode={isRangeMode}
        disabledFuture={disabledFuture}
      />
      <div
        css={_`flex: 1 1 auto; display: flex; position: relative; padding: 0 4px`}
      >
        {selectorType === 'day' && (
          <DatesArea
            disabledFuture={disabledFuture}
            disabledPast={disabledPast}
            disableCurrentDay={disableCurrentDay}
            onChange={handleClick}
            month={monthDate}
            selected={mode === 'range' ? undefined : date}
            range={range}
          />
        )}
        {selectorType === 'month' && (
          <MonthsArea
            onChange={(d) => {
              onChangeMonthDate(d, order);
              setSelectorType('day');
            }}
            date={monthDate}
            selected={mode === 'range' ? range?.[order] : date}
          />
        )}
        {selectorType === 'year' && (
          <YearArea
            reverseYears={reverseYears}
            onChange={(d) => {
              onChangeMonthDate(d, order);
              setSelectorType('day');
            }}
            date={monthDate}
            selected={mode === 'range' ? range?.[order] : date}
            range={onlyFutureYears ? [DateTime.now().year, 2099] : [1900, DateTime.now().year]}
          />
        )}
      </div>
    </BodyContainer>
  );
};
