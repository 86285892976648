
export const calcMinColWidth = (lg: boolean, xl: boolean) => {
  if (lg) return 118;
  if (xl) return 122;
  return 233;
};

export const getSidebarOffset = (isSidebarOpen: boolean, xxl: boolean) => {
  if (isSidebarOpen && !xxl) {
    return 286;
  } else if (xxl && isSidebarOpen) {
    return 282;
  } else {
    return 6;
  }
};
