import {HealthcareInsurancePolicy} from '@src/api';

export const calcWBoxPaddings = (
  sortedInsurance: HealthcareInsurancePolicy[],
  insurance: HealthcareInsurancePolicy,
) => {
  if (sortedInsurance.length < 2) return 24;
  return sortedInsurance.length === 2 && insurance.primary
    ? '24px 24px 0 24px'
    : '0 24px 24px 24px';
};
