import {Box, debounce, InputAdornment, Menu, MenuItem, Stack, TextField, Typography} from '@mui/material';
import {MobileInteractionView} from '@src/components/MobileInteractionView';
import {DEFAULT_SCROLL_OFFSET_FETCH} from '@src/components/MultiSelect/constants';
import {MSelectOption, MultiSelectProps} from '@src/components/MultiSelect/types';
import {useTranslate} from '@src/i18n/useTranslate';
import {useMQuery} from '@src/shared/hooks';
import {ReactComponent as SearchIcon} from '@src/shared/assets/icons/search-small.svg';
import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Checkbox, InputControl} from 'ui-kit';

import {sx} from './styles';

export const MultiSelect: FC<MultiSelectProps> = ({
  options,
  renderValue,
  value,
  onChange,
  label,
  placeholder = 'Select',
  onFullMobileClose,
  onNext,
  optional,
  inputsSx,
  compact,
  ignoreLabelHeight,
  searchValue,
  onChangeSearchValue,
  onClear
}) => {
  const {t} = useTranslate('common');
  const {mobile} = useMQuery();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const filteredOptions = useMemo(() => {
    if (!searchValue) return options;
    return options.filter((option) =>
      option.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [options, searchValue]);

  const optionsName = useMemo(() => {
    const valueNames: Record<string, string> = {};
    options.forEach(({name, value}) => {
      valueNames[value] = name;
    });

    return valueNames;
  }, [options]);

  useEffect(() => {
    setSelectedOptions(value || []);
  }, [value]);

  useEffect(() => {
    inputRef.current?.addEventListener('click', handleOpen);
  }, []);

  const handleOpen = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget as HTMLInputElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedOptions(value || []);
    onClear?.();
  };

  const handleSelectOption = (optionValue: MSelectOption['value'], checked: boolean) => {
    if (checked) {
      const newOptions = selectedOptions.filter((option) => option !== optionValue);
      setSelectedOptions(newOptions);
      return;
    }
    setSelectedOptions((prev) => [...prev, optionValue]);
  };

  const handleApply = () => {
    onChange(selectedOptions);
    setAnchorEl(null);
    onClear?.();
  };

  const handleClear = () => {
    setSelectedOptions([]);
    onClear?.();
  };

  const handleFetchNext = debounce(() => onNext?.(), 300);

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const {scrollTop, scrollHeight, offsetHeight} = e.currentTarget;
    if (scrollHeight - offsetHeight < scrollTop + DEFAULT_SCROLL_OFFSET_FETCH) {
      handleFetchNext();
    }
  };

  const renderNotFound = () => {
    return (
      !filteredOptions.length && (
        <Box sx={sx.notFound}>
          <Typography variant="14_18_500" fontStyle={'italic'} color={'grey.400'}>
            {t('NO_RESULTS_FOUND')}
          </Typography>
        </Box>
      )
    )
  };

  return (
    <>
      <InputControl
        readOnly
        optional={optional}
        label={label}
        compact={compact}
        ignoreLabelHeight={ignoreLabelHeight}
        placeholder={placeholder}
        value={
          renderValue?.(selectedOptions.map((option) => optionsName[option])) ||
          selectedOptions.map((option) => optionsName[option]).join(', ')
        }
        inputRef={inputRef}
        sx={{
          '.MuiInputBase-input': {
            cursor: 'pointer',
          },
          '.MuiInputBase-root:after': anchorEl && {
            border: '2px solid',
            borderColor: 'grey.600',
          },
          ...inputsSx,
        }}
      />
      {mobile && (
        <MobileInteractionView
          isOpen={!!anchorEl}
          title={label || 'Select'}
          onBack={handleClose}
          onClose={() => {
            handleClose();
            onFullMobileClose?.();
          }}
        >
          {onChangeSearchValue && (
            <TextField
              fullWidth
              InputProps={{
                sx: sx.searchInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder={t('SEARCH')}
              value={searchValue}
              onChange={onChangeSearchValue}
            />)
          }
          {renderNotFound()}
          <Stack sx={{overflowY: 'scroll', flex: 1}} onScroll={handleScroll}>
            {filteredOptions.map(({name, value}) => {
              const checked = selectedOptions.includes(value);
              return (
                <MenuItem
                  sx={sx.option}
                  key={value}
                  onClick={() => handleSelectOption(value, checked)}
                >
                  <Checkbox value={checked} />
                  <Typography variant={'14_18_500'}>{name}</Typography>
                </MenuItem>
              );
            })}
          </Stack>
          <Stack p={18} gap={12}>
            <Button color={'secondary'} onClick={handleApply}>
              {t('APPLY')}
            </Button>
            <Button color={'secondary'} variant={'outlined'} onClick={handleClear}>
              {t('CLEAR_ALL')}
            </Button>
          </Stack>
        </MobileInteractionView>
      )}
      {!mobile && (
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
          MenuListProps={{
            sx: {
              p: 0,
              width: anchorEl?.offsetWidth,
              minWidth: 400,
            },
          }}
        >
          {onChangeSearchValue && (
            <TextField
              fullWidth
              InputProps={{
                sx: sx.searchInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder={t('SEARCH')}
              value={searchValue}
              onChange={onChangeSearchValue}
            />)
          }
          <Stack sx={{ maxHeight: 252, overflowY: 'scroll' }} onScroll={handleScroll}>
            {renderNotFound()}
            {filteredOptions.map(({name, value}) => {
              const checked = selectedOptions.includes(value);
              return (
                <MenuItem
                  sx={sx.option}
                  key={value}
                  onClick={() => handleSelectOption(value, checked)}
                >
                  <Checkbox value={checked} />
                  <Typography variant={'14_18_500'}>{name}</Typography>
                </MenuItem>
              );
            })}
          </Stack>
          <Stack sx={sx.buttons}>
            <Box flex={1}>
              <Button color={'secondary'} variant={'outlined'} onClick={handleClose}>
                {t('CANCEL')}
              </Button>
            </Box>
            <Button color={'secondary'} variant={'outlined'} onClick={handleClear}>
              {t('CLEAR_ALL')}
            </Button>
            <Button color={'secondary'} onClick={handleApply}>
              {t('APPLY')}
            </Button>
          </Stack>
        </Menu>
      )}
    </>
  );
};
