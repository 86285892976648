import {Box, GlobalStyles} from '@mui/material';
import {Main, PageBody, globalStyles} from '@src/features/layouts/MainLayout/styles';
import {useRecaptcha} from '@src/shared/hooks/useRecaptcha';
import {useFormik} from 'formik';
import {isEqual} from 'lodash-es';
import {useSearchParams} from 'react-router-dom';

import {PdfResultsView} from './PdfResultsView/PdfResultsView';
import {ResultForm} from './ResultForm/ResultForm';
import {useSearchData, useValidationSchema} from './hooks';
import {sx} from './styles';

export const PdfResults = () => {
  useRecaptcha();
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
  const validationSchema = useValidationSchema();
  const {searchError, result, searchResultHandler, errorTime} = useSearchData();

  const initialValue = {
    lastName: '',
    key,
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: initialValue,
    validationSchema,
    onSubmit: async (values) => {
      if (!isEqual(initialValue, values)) {
        if (values.lastName) {
          searchResultHandler(values);
        };
      }
    },
  });

  return (
    <>
      <GlobalStyles styles={globalStyles} />
      <PageBody>
        {!result && key
          ? (
            <Main sx={{marginTop: 0}}>
              <ResultForm
                lastName={formik.values.lastName || ''}
                error={formik.touched?.lastName ? formik.errors.lastName || searchError : ''}
                errorTime={errorTime}
                onChange={formik.handleChange}
                onSubmit={formik.handleSubmit}
              />
            </Main>
          )
          : (
            <Box
              sx={sx.pdfResultsWrapper}>
              <PdfResultsView resultData={result} />
            </Box>
          )
        }
      </PageBody>
    </>
  );
};
