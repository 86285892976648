import {Box, SxProps, Theme, styled} from '@mui/material';
import {GlobalWorkerOptions, getDocument} from 'pdfjs-dist';
import {useEffect, useState} from 'react';
import {PulseLoader} from '../PulseLoader';
import {Img} from './styles';
// cause don't have type resolution for worker
// @ts-expect-error
import PdfjsWorker from 'pdfjs-dist/build/pdf.worker.js';

GlobalWorkerOptions.workerPort = new PdfjsWorker();

interface Props {
  data: string // base64 string
  sx?: SxProps<Theme>
};

const usePdfBase64ToImage = (base64Data: string) => {
  const [imgSrc, setImgSrc] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const renderPDF = async () => {
      setLoading(true);
      // load PDF from base64
      const loadingTask = getDocument({data: atob(base64Data)});
      const pdf = await loadingTask.promise;

      // getting first page of PDF
      const pageNumber = 1;
      const page = await pdf.getPage(pageNumber);
      // getting page's size
      const viewport = page.getViewport({scale: 2});

      // draw PDF on canvas
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const renderContext = {
        canvasContext: context as CanvasRenderingContext2D,
        viewport,
      };
      await page.render(renderContext).promise;
      // transform to png
      setImgSrc(canvas.toDataURL('image/png'));
      setLoading(false);
    };

    renderPDF().catch(err => {
      console.error(err);
    });

    return () => {
      // clear resources
      GlobalWorkerOptions.workerSrc = '';
    };
  }, [base64Data]);

  return {loading, imgSrc};
};

export const PdfViewer: React.FC<Props> = ({data, sx}) => {
  const {loading, imgSrc} = usePdfBase64ToImage(data);

  return (
    <Box sx={sx}>
      {loading ? <PulseLoader loading={loading} /> : <Img src={imgSrc} />}
    </Box>
  );
};
