import {SxProps, Theme, styled} from '@mui/material';
import {Form} from 'formik';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: (t) => ({
    position: 'relative',
    [t.breakpoints.up('lg')]: {
      borderTop: '1px solid',
      borderColor: t.palette.secondary[300],
    },
    padding: 24,
    gap: 40,
    flexGrow: 1,
    [t.breakpoints.between('sm', 'lg')]: {
      width: 'auto',
      border: 'none',
      p: 0,
      gap: 24,
    },
    [t.breakpoints.only('xs')]: {
      p: 0,
      width: 'auto',
    },
  }),
  content: {
    flexGrow: 1,
    gap: {xs: 0, sm: 28, lg: 24},
    mt: {xs: 18, sm: 24, lg: 0},
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'end',
    gap: 12,
    mt: {lg: 36},
  },
  title: {
    mt: {xs: 18, sm: 24, lg: 0},
    typography: {xs: '18_24_500', sm: '20_24_500', lg: '24_34_500'},
    mb: {xs: 16, sm: 0},
  },
  divider: (t) => ({
    position: 'absolute',
    left: {xs: -18, sm: -24},
    right: {xs: -18, sm: -24},
    top: {xs: 18, sm: 24},
    borderColor: t.palette.secondary[300],
  }),
  inputControl: {
    mt: {xs: 28, sm: 0},
  },
};

export const StyledForm = styled(Form)(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  flexGrow: 1,
  [theme.breakpoints.between('sm', 'lg')]: {
    gap: 36,
  },
  [theme.breakpoints.only('xs')]: {
    gap: 36,
  },
}));
