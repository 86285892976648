import {Theme, SxProps} from '@mui/material';
import {alpha} from '@src/theme/utils';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  tableContainer: (t) => ({
    borderTop: `1px solid ${alpha(t.palette.secondary.main, 14)}`,
    thead: {
      tr: {
        height: 52,
        th: {height: 52},
      },
    },
    tr: {
      gap: 24,
      borderBottom: `1px solid ${alpha(t.palette.secondary.main, 14)}`,
      th: {
        borderBottom: 'none',
        padding: '12px 0',
        display: 'flex',
        alignItems: 'center',
        height: 64,
      },
      td: {
        borderBottom: 'none',
        padding: '12px 0',
        display: 'flex',
        alignItems: 'center',
        height: 64,
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px',
        minWidth: 100,
      },
    },
  }),
  tableRow: (t) => ({
    display: 'grid',
    gridTemplateColumns: '220px 1fr 1fr 1fr 1fr 1fr',
    padding: '0 36px 0 36px',
    [t.breakpoints.between('sm', 'lg')]: {
      gridTemplateColumns: '35% 25% 13% 72px',
      padding: '0 24px 0 24px',
    },
  }),
  tablePairsSwitch: {
    pl: 9,
    pr: 9,
    width: 42,
  },
  paginationWrapper: (t) => ({
    display: 'flex',
    justifyContent: 'end',
    padding: '0 36px 36px 36px',
    flexGrow: 1,
    [t.breakpoints.between('sm', 'lg')]: {
      padding: '0 24px 24px 24px',
    },
  }),
  paginationContent: {
    '.MuiInputBase-root': {display: 'none'},
    '.MuiTablePagination-displayedRows': {display: 'none'},
    border: 'none',
    display: 'flex',
    alignItems: 'end',
  },
  requestAvatar: (t) => ({
    width: 40,
    height: 40,
    backgroundColor: t.palette.secondary[400],
    color: t.palette.common.white,
  }),
  personType: (t) => ({
    minHeight: 20,
    maxWidth: 232,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [t.breakpoints.only('xs')]: {
      typography: '12_18_500',
    },
  }),
  personName: {
    maxWidth: 220,
  },
  personEmail: {
    maxWidth: 220,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  flag: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 8px',
  },
  patientNotFound: (t) => ({
    color: t.colors.all.grey[500],
    pl: 36,
    [t.breakpoints.between('sm', 'lg')]: {
      pl: 24,
    },
  }),
  presonColumnWrapper: {
    flexDirection: 'row',
    gap: 12,
    overflow: 'hidden',
  },
  personNameWrapper: {
    justifyContent: 'center',
    overflow: 'hidden',
    pr: 12,
  },
};
