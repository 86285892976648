import {storageApi} from '@api';
import {Menu} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as MessageIcon} from '@src/shared/assets/icons/24x24/message.svg';
import {ReactComponent as ShareIcon} from '@src/shared/assets/icons/share.svg';
import {useMQuery} from '@src/shared/hooks';
import {notifyActions} from '@src/store/notifications/slice';
import {useAppDispatch} from '@store';
import {FC, useRef, useState} from 'react';
import {Button} from 'ui-kit';

import {arraysIsEqual} from './helpers';
import {ButtonClickFunction, ShareDocumentsMenuProps} from './types';
import {envs} from '@src/shared/constants/envs';

export const ShareDocumentsMenu: FC<ShareDocumentsMenuProps> = ({
  children,
  documentIds,
  externalIsMobile,
}) => {
  const {t} = useTranslate('storage');
  const dispatch = useAppDispatch();
  const {mobile} = useMQuery();
  const isMobile = mobile || externalIsMobile;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [shareLink, setShareLink] = useState('');

  const previousIds = useRef(documentIds);

  const handleGetShareLink = async () => {
    if (!documentIds.length) {
      return;
    }
    if (arraysIsEqual(previousIds.current, documentIds) && shareLink) {
      return shareLink;
    }
    try {
      const newShareLink = await storageApi.shareStorageFiles(documentIds);
      setShareLink(newShareLink.url);
      previousIds.current = documentIds;
      return newShareLink.url;
    } catch (e) {
      dispatch(
        notifyActions.showNotifications([
          {
            id: '',
            type: 'error',
            text: t('SMTH_WENT_WRONG', { link: envs.PATIENT_CONTACT_SUPPORT_URL }),
          },
        ])
      );
      console.error(e);
    }
  };

  const handleCopyLink = async () => {
    const shareLink = await handleGetShareLink();
    if (!shareLink) return;

    await navigator.clipboard.writeText(shareLink);
    dispatch(
      notifyActions.showNotifications([
        {id: shareLink || '', type: 'success', text: t('LINK_COPIED')},
      ]),
    );
  };

  const handleSendEmail = async () => {
    const shareLink = await handleGetShareLink();
    if (!shareLink) return;

    const a = document.createElement('a');
    a.href = `mailto:?body=${shareLink}`;
    a.click();
    a.remove();
  };

  const handleShare = async () => {
    const shareLink = await handleGetShareLink();
    if (!shareLink) return;

    try {
      await navigator.share({
        url: shareLink,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleButtonClick: ButtonClickFunction = (event) => {
    if (isMobile) {
      void handleShare();
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (callback: () => Promise<void>) => () => {
    handleClose();
    void callback();
  };

  return (
    <>
      {children(handleButtonClick)}
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          sx: {
            p: 0,
            width: 180,
          },
        }}
      >
        <Button
          startIcon={<ShareIcon />}
          color={'black'}
          variant={'text'}
          sx={{pl: 12, justifyContent: 'flex-start'}}
          fullWidth
          onClick={handleMenuItemClick(handleCopyLink)}
        >
          {t('COPY_LINK')}
        </Button>
        <Button
          startIcon={<MessageIcon />}
          color={'black'}
          variant={'text'}
          sx={{pl: 12, justifyContent: 'flex-start'}}
          fullWidth
          onClick={handleMenuItemClick(handleSendEmail)}
        >
          {t('SEND_EMAIL')}
        </Button>
      </Menu>
    </>
  );
};
