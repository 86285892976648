import {useTheme} from '@mui/material';
import {FC} from 'react';
import {Bar, BarChart, ResponsiveContainer, XAxis} from 'recharts';
import {PropsType} from './types';

export const MiniCharts: FC<PropsType> = ({data}) => {
  const theme = useTheme();
  const barColor = theme.palette.secondary[300];
  const axiColor = theme.palette.grey[300];
  return (
    <ResponsiveContainer width="100%" height="100%" >
      <BarChart
        data={data}
        margin={{
          bottom: 0,
          top: 0,
          left: 0,
          right: 0,
        }}>
        <Bar maxBarSize={25} dataKey="value" fill={barColor} />
        <XAxis
          dataKey="createdTime"
          height={1}
          tick={false}
          fill={axiColor}
          stroke={axiColor}/>
      </BarChart>
    </ResponsiveContainer>
  );
};
