import {Typography, Stack, Box, Avatar} from '@mui/material';
import {RelationContactSimilarErrorType} from '@src/api/relations';
import {RowInformation} from '@src/components';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@src/components/Dialog';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {ListWrapper} from '@src/components/ListWrapper';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as Attention} from '@src/shared/assets/icons/attention.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {getUserInitials} from '@src/shared/utils';
import {formatPhoneNumber} from '@src/shared/utils/formatPhoneNumber';
import {getName} from '@src/shared/utils/getName';
import {relationsActions} from '@src/store/relations/slice';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Button} from 'ui-kit';

import {AddRelationTableAction} from '../../../AddRelationTable/AddRelationTableAction';

import {SIMILAR_STRATEGY_ERROR} from './constants';
import {showCurrError} from './helpers';
import {useModalContentMap} from './hooks';
import {sx} from './styles';
import {IFailureModal} from './types';

export const FailureModal = ({
  failureCreatedContact,
  relationType,
  setTouched,
  handleCreateRelationContact,
}: IFailureModal) => {
  const {t} = useTranslate('connections');
  const {mobile: isMobile, tablet: isTablet} = useMQuery();

  const dispatch = useDispatch();

  const [isSuccessModalOpened, setIsSuccessModalOpened] = useState<boolean>(true);

  const {errors} = failureCreatedContact;

  const closeFailureModal = () => {
    setIsSuccessModalOpened(false);
    setTouched({});
    dispatch(relationsActions.setFailureCreatedContact({failureCreatedContact: null}));
  };

  const {modalContentMap} = useModalContentMap();

  const relationContactErrorTypes = errors.map((error) => error.type);

  const {types: currentTypes, filteredErrors} = showCurrError(errors);

  const hasRelationContactSimilarError = relationContactErrorTypes?.includes(SIMILAR_STRATEGY_ERROR);

  const rowInfoNameTypography = isMobile ? '12_16_700' : '14_18_700';
  const rowInfoValueTypography = isMobile ? '12_16_500' : '14_18_500';

  const isUniqueUnconfirmedPhoneError =
    currentTypes[0] === RelationContactSimilarErrorType.UNIQUE_UNCONFIRMED_PHONE;

  const shouldShowCreateNewPatientBtn = isUniqueUnconfirmedPhoneError && !hasRelationContactSimilarError;

  const modalSize = () => {
    if (isMobile) return '100%';
    if (isTablet) return 'md';
    return 'lg';
  };

  useEffect(() => {
    setIsSuccessModalOpened(true);
  }, [failureCreatedContact]);

  return (
    <Dialog
      sx={sx.dialog}
      border={'none'}
      open={isSuccessModalOpened}
      onClose={closeFailureModal}
      size={modalSize()}
    >
      <DialogTitle width="62px" onClose={closeFailureModal} noContent={false}>
        <Typography variant="18_24_700">{t('DUPLICATE_FOUND')}</Typography>
      </DialogTitle>
      <DialogContent sx={sx.dialogContent}>
        <Stack>
          {currentTypes.map((errorType) => {
            const textInfo = modalContentMap[errorType];

            const patientsForErrorType = filteredErrors[errorType] || [];

            return (
              <Box key={errorType}>
                <Box sx={sx.boxContainer}>
                  <Box
                    sx={sx.attentionInfoContainer}>
                    <Attention />
                    <Typography sx={sx.textInfo} variant={'14_18_700'}>{textInfo}</Typography>
                  </Box>
                </Box>
                {patientsForErrorType.length > 0 && (
                  <Stack sx={sx.infoWrapper}>
                    {patientsForErrorType.map((patient, index) => (
                      <Box key={index} sx={sx.boxInfoContainer}>
                        <Stack sx={sx.infoContainer}>
                          <Avatar sx={sx.avatar} src={patient?.accountAvatarUrl}>
                            <Typography variant="16_20_700">{getUserInitials(patient)}</Typography>
                          </Avatar>
                          <Stack mt={3} minWidth="10%">
                            <EllipsisTextTooltip
                              variant="14_18_700"
                              ellipsisText={getName(patient, {short: true})}
                            />
                            <EllipsisTextTooltip
                              variant="12_16_500"
                              ellipsisText={patient?.birthDateS}
                            />
                          </Stack>
                        </Stack>
                        <Stack p={{xs: '0 18px', sm: '0 24px'}}>
                          <ListWrapper padding="8px" noLast={{xs: 0}}>
                            <RowInformation
                              spaceBetween={8}
                              tooltip
                              name={t('PHONE')}
                              variant={rowInfoNameTypography}
                              valueTypographyVariant={rowInfoValueTypography}
                              value={formatPhoneNumber(patient?.phone)}
                              noMargin
                            />
                            <RowInformation
                              spaceBetween={isMobile ? 8 : 12}
                              variant={rowInfoNameTypography}
                              valueTypographyVariant={rowInfoValueTypography}
                              tooltip
                              name={t('EMAIL')}
                              value={patient?.email}
                              noMargin
                            />
                            <RowInformation
                              spaceBetween={isMobile ? 8 : 12}
                              variant={rowInfoNameTypography}
                              valueTypographyVariant={rowInfoValueTypography}
                              tooltip
                              name={t('SSN_LAST_4')}
                              value={patient?.ssnTail}
                              noMargin
                            />
                            <RowInformation
                              spaceBetween={isMobile ? 8 : 12}
                              variant={rowInfoNameTypography}
                              valueTypographyVariant={rowInfoValueTypography}
                              tooltip
                              name={t('POLICY_ID')}
                              value={patient?.policyNumber}
                              noMargin
                            />
                          </ListWrapper>
                        </Stack>
                        <Stack sx={sx.flexContainer}>
                          <AddRelationTableAction
                            isModal
                            variant="text"
                            id={patient?.id}
                            relationError={patient?.relationError}
                            newContactBirthDate={patient.birthDateS}
                            relationshipType={relationType || null}
                            closeModal={closeFailureModal}
                          />
                        </Stack>
                      </Box>
                    ))}
                  </Stack>
                )}
              </Box>
            );
          })}
        </Stack>
      </DialogContent>
      {shouldShowCreateNewPatientBtn
        ? (
          <DialogActions sx={sx.dialogActions}>
            <Button
              fullWidth={isMobile}
              variant="outlined"
              color="secondary"
              sx={sx.bthCancel}
              onClick={() => {
                closeFailureModal();
              }}
            >
              {t('CANCEL')}
            </Button>
            <Button
              fullWidth={isMobile}
              variant="contained"
              color="secondary"
              sx={sx.bthCreatePatient}
              onClick={() => {
                handleCreateRelationContact();
                closeFailureModal();
              }}
            >
              {t('CREATE_A_NEW_PATIENT')}
            </Button>
          </DialogActions>
        )
        : (
          <DialogActions sx={sx.dialogActionsFailure}>
            <Button
              fullWidth={isMobile}
              variant="contained"
              color="secondary"
              onClick={() => {
                closeFailureModal();
              }}
            >
              {t('BACK')}
            </Button>
          </DialogActions>
        )}
    </Dialog>
  );
};
