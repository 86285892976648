import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  requestAvatar: {
    width: 40,
    height: 40,
    mr: 12,
    backgroundColor: (t) => t.palette.secondary[600],
    color: (t) => t.palette.common.white,
  },
  flagCell: {
    width: 'inherit',
    fontSize: {
      xs: 12,
      sm: 16,
      lg: 18,
    },
    display: {
      xs: 'flex',
      lg: 'table-cell',
    },
    pb: {
      xs: 0,
      sm: 'inherit',
    },
    pr: 0,
    pt: 0,
    borderBottom: 'none',
  },
  patientNotFound: {
    color: (t) => t.palette.grey[500],
    padding: '4px 16px 0px 12px',
  },
  wBoxBthContainer: {
    padding: '16px',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    position: 'fixed',
    bottom: 0,
    right: 0,
  },
  searchContainer: {
    p: '12px 18px',
    zIndex: 2,
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    position: 'fixed',
    top: 64,
    right: 0,
  },
  searchContent: {
    flexDirection: 'row',
    gap: 12,
    alignItems: 'end',
  },
  flexContainer: {
    flexDirection: 'row',
    gap: 4,
    alignItems: 'center',
    maxWidth: 'fit-content',
  },
  searchContainerDesk: {
    flexDirection: 'row',
    gap: 12,
    alignItems: 'end',
  },
  desktopFlagCellContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 24,
    width: 'fit-content',
  },
  bth: {
    minWidth: 42,
    p: 0,
  },
  container: {
    p: '18px 12px 12px 12px',
    gap: 18,
  },
  flexContainerInfo: {
    flexDirection: 'row',
    alignItems: 'center',
    mb: 12,
  },
  widthLimitContainer: {
    display: 'grid',
    minWidth: '10%',
  },
  loaderContainer: {
    width: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: '18px 18px 30px 18px',
  },
};
