import {
  RelationContactErrors,
  RelationContactSearchResult,
  RelationContactSimilarErrorType,
} from '@src/api/relations';

import {
  SIMILAR_STRATEGY_ERROR,
  UNIQUE_CONFIRMED_PHONE_ERROR,
  UNIQUE_UNCONFIRMED_PHONE_ERROR,
} from './constants';

export const showCurrError = (
  errors: RelationContactErrors[],
): { types: RelationContactSimilarErrorType[], filteredErrors: Record<string, RelationContactSearchResult[]> } => {
  const relationContactErrorTypes = errors.map((error) => error.type);
  const errorsToShow: RelationContactSimilarErrorType[] = [];
  const filteredErrors: Record<string, RelationContactSearchResult[]> = {};

  const getResults = (errorType: RelationContactSimilarErrorType) =>
    errors.find((err) => err.type === errorType)?.results || [];

  const addError = (errorType: RelationContactSimilarErrorType) => {
    if (relationContactErrorTypes.includes(errorType) && !errorsToShow.includes(errorType)) {
      errorsToShow.push(errorType);
    }
  };

  const addFilteredError = (
    errorType: RelationContactSimilarErrorType,
    results: RelationContactSearchResult[],
  ) => {
    if (results.length > 0) {
      filteredErrors[errorType] = results;
    }
  };

  const checkResultsEquality = (
    type1: RelationContactSimilarErrorType,
    type2: RelationContactSimilarErrorType,
  ) => {
    const results1 = getResults(type1);
    const results2 = getResults(type2);

    return (
      results1.length === results2.length &&
      results1.every((result) => results2.some((strategyResult) => strategyResult.id === result.id))
    );
  };

  if (
    relationContactErrorTypes.includes(UNIQUE_CONFIRMED_PHONE_ERROR) &&
    relationContactErrorTypes.includes(SIMILAR_STRATEGY_ERROR)
  ) {
    const confirmedResults = getResults(UNIQUE_CONFIRMED_PHONE_ERROR);
    const strategyResults = getResults(SIMILAR_STRATEGY_ERROR);

    if (checkResultsEquality(UNIQUE_CONFIRMED_PHONE_ERROR, SIMILAR_STRATEGY_ERROR)) {
      addError(UNIQUE_CONFIRMED_PHONE_ERROR);
      addFilteredError(UNIQUE_CONFIRMED_PHONE_ERROR, confirmedResults);
    } else {
      addError(UNIQUE_CONFIRMED_PHONE_ERROR);
      addError(SIMILAR_STRATEGY_ERROR);
      addFilteredError(UNIQUE_CONFIRMED_PHONE_ERROR, confirmedResults);
      addFilteredError(
        SIMILAR_STRATEGY_ERROR,
        strategyResults.filter(
          (result) => !confirmedResults.some((confResult) => confResult.id === result.id),
        ),
      );
    }
  } else {
    addError(UNIQUE_CONFIRMED_PHONE_ERROR);
    addFilteredError(UNIQUE_CONFIRMED_PHONE_ERROR, getResults(UNIQUE_CONFIRMED_PHONE_ERROR));
    addError(SIMILAR_STRATEGY_ERROR);
    addFilteredError(SIMILAR_STRATEGY_ERROR, getResults(SIMILAR_STRATEGY_ERROR));
  }

  if (
    relationContactErrorTypes.includes(UNIQUE_UNCONFIRMED_PHONE_ERROR) &&
    relationContactErrorTypes.includes(SIMILAR_STRATEGY_ERROR)
  ) {
    addError(SIMILAR_STRATEGY_ERROR);
    addFilteredError(SIMILAR_STRATEGY_ERROR, getResults(SIMILAR_STRATEGY_ERROR));
  }

  if (
    relationContactErrorTypes.includes(UNIQUE_CONFIRMED_PHONE_ERROR) &&
    relationContactErrorTypes.includes(UNIQUE_UNCONFIRMED_PHONE_ERROR)
  ) {
    addError(UNIQUE_CONFIRMED_PHONE_ERROR);
    addFilteredError(UNIQUE_CONFIRMED_PHONE_ERROR, getResults(UNIQUE_CONFIRMED_PHONE_ERROR));
  }

  if (errorsToShow.length === 0) {
    addError(UNIQUE_UNCONFIRMED_PHONE_ERROR);
    addFilteredError(UNIQUE_UNCONFIRMED_PHONE_ERROR, getResults(UNIQUE_UNCONFIRMED_PHONE_ERROR));
  }

  return {types: errorsToShow, filteredErrors};
};
