import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: (t) => ({
    border: '1px solid',
    borderColor: t.palette.secondary[300],
    position: 'relative',
  }),
  boxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 24,
    p: {xs: '14px 0', sm: '16px 0'},
    position: 'relative',
  },
  line: (t) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: {xs: 54, sm: 66},
    borderColor: t.palette.secondary[300],
  }),
  title: {
    typography: {xs: '22_26_500', sm: '24_34_500'},
  },
};
