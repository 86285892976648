import {SxProps, Theme} from '@mui/material';
import {alpha} from '@src/theme/utils';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  dialog: {
    '.MuiPaper-root': {
      maxHeight: {xs: '100%', sm: 784},
      height: {xs: '100%', sm: 'initial'},
      width: '100% !important',
    },
    '.MuiDialogContent-root': {overflowY: 'auto'},
  },
  avatar: (t) => ({
    width: 42,
    height: 42,
    backgroundColor: t.palette.secondary[600],
    color: t.palette.common.white,
  }),
  bthCancel: {
    minWidth: 102,
    p: {
      xs: 0,
    },
  },
  bthCreatePatient: {
    minWidth: 168,
  },
  boxContainer: {
    p: {xs: '0 18px 18px 18px', sm: '0 24px 18px 24px'},
    display: 'flex',
  },
  textInfo: {
    ml: {xs: 36, sm: 0},
    mt: {xs: 2, sm: 0},
  },
  attentionInfoContainer: (t) => ({
    position: 'relative',
    p: {xs: '14px 12px', sm: 12},
    display: 'flex',
    gap: 12,
    width: '100%',
    alignItems: {xs: 'baseline', sm: 'center'},
    backgroundColor: alpha(t.colors.all.attention, 10),
    svg: {
      path: {
        fill: t.colors.all.attention,
      },
    },
    [t.breakpoints.only('xs')]: {
      '& > svg': {
        position: 'absolute',
        top: 13,
      },
      svg: {
        height: 24,
        width: 24,
        flexShrink: 0,
      },
    },
  }),
  infoWrapper: {
    p: {xs: '0 18px 18px 18px', sm: '0 24px 18px 24px'},
    maxHeight: '100%',
    gap: 18,
    mr: {xs: 0, sm: 24},
  },
  dialogContent: {
    overflow: 'hidden',
    textAlign: 'initial !important',
    padding: {xs: '18px 0 0 0 !important', sm: '24px 0 0 0 !important'},
  },
  infoContainer: {
    p: {xs: '18px 18px 12px 18px', sm: '24px 24px 12px 24px'},
    flexDirection: 'row',
    gap: 12,
  },
  boxInfoContainer: (t) => ({
    border: '1px solid',
    borderColor: t.palette.grey[400],
    width: {xs: '100%', sm: 362},
    height: {xs: 242, sm: 262},
  }),
  dialogActions: (t) => ({
    justifyContent: {xs: 'center !important', sm: 'space-between !important'},
    margin: 0,
    padding: {xs: 18, sm: 24},
    flexDirection: {xs: 'column-reverse !important', sm: 'row !important'},
    borderTop: '1px solid',
    borderTopColor: t.palette.secondary[400] || '',
  }),
  dialogActionsFailure: (t) => ({
    justifyContent: 'end !important',
    margin: 0,
    padding: 24,
    borderTop: '1px solid',
    borderTopColor: t.palette.secondary[400] || '',
    [t.breakpoints.only('xs')]: {
      flexDirection: 'row !important',
      p: 18,
    },
  }),
  flexContainer: {
    p: {xs: '24px 18px 18px 18px', sm: 24},
    width: '100%',
  },
};
