import {InsurancePlansList} from '@src/api/insurances';
import {useTranslate} from '@src/i18n/useTranslate';
import {compareDatesWithToday} from '@src/shared/utils/compareDatesWithToday';
import {postalCodeValidation} from '@src/shared/utils/postalCodeValidation';
import {phoneNotRequiredValidator, phoneValidator} from '@src/shared/utils/validators';
import {useInsuranceCarriers, useInsurancePlans} from '@src/store/insurance/hooks';
import {useAppSelector} from '@store';
import {differenceInYears} from 'date-fns';
import {useEffect, useState} from 'react';
import validator from 'validator';
import * as Yup from 'yup';
import {AnyObject} from 'yup/lib/types';

import {checkIsPeroson18OrOlder} from '../helpers/checkIsPeroson18OrOlder';

import {EWhichPrimaryInsuranceUse} from './types';

export const useValidationSchema = (isOptionSelected: boolean) => {
  const {t} = useTranslate('connections');
  const account = useAppSelector((state) => state.account.account);

  function checkIfTheInsuranceFieldsWasField (this: Yup.TestContext<AnyObject>) {
    const condition = this.parent.insurancePlan ||
            this.parent.guarantorAddress1 ||
            this.parent.guarantorPostalCode ||
            this.parent.guarantorFirstName ||
            this.parent.guarantorLastName ||
            this.parent.guarantorBirthDate ||
            this.parent.guarantorBirthSex ||
            this.parent.guarantorCity ||
            this.parent.guarantorState ||
            this.parent.guarantorSsnTail ||
            this.parent.guarantorAddress2 ||
            this.parent.guarantorPhone ||
            this.parent.guarantorMiddleName ||
            this.parent.guarantorRelationshipType ||
            this.parent.endDate ||
            this.parent.policyId ||
            this.parent.insuranceCarrier;
    return condition;
  };

  return Yup.object({
    streetNumber: Yup.string()
      .nullable()
      .test('is-clickToOption', t('ENTER_VALID_ADDRESS'), function () {
        return isOptionSelected || !this.parent.address1;
      })
      .test('is-addressStreetNumber', t('PLEASE_PROVIDE_THE_BUILDING_ADDRESS'), function () {
        const hasAddressError = !this.parent.city || !this.parent.postalCode;
        if (this.parent.address1 && hasAddressError) {
          return false;
        }
        return true;
      }),
    guarantorPhone: phoneNotRequiredValidator(t, account?.phone, true),
    guarantorPostalCode: Yup.string()
      .nullable()
      .test('is-guarantorPostalCode', t('REQUIRED_FIELD'), function (value) {
        if (this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient && value) {
          return postalCodeValidation(value);
        } else if (
          this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient &&
          !value &&
          checkIfTheInsuranceFieldsWasField.call(this)
        ) {
          return false;
        } else if (this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.My) {
          return true;
        }
        return true;
      }),
    guarantorAddress1: Yup.string()
      .nullable()
      .test('custom-validation', t('REQUIRED_FIELD'), function (value) {
        const {whichPrimaryInsuranceUse} = this.parent;
        const isPatient = whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient;
        const isMy = whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.My;

        const isNotEmpty = value !== null && value !== undefined && value.trim() !== '';

        if (isPatient) {
          if (isNotEmpty) {
            return true;
          }
          if (!isNotEmpty && checkIfTheInsuranceFieldsWasField.call(this)) {
            return false;
          }
        }

        if (isMy) {
          return true;
        }

        return true;
      }),
    guarantorFirstName: Yup.string()
      .nullable()
      .test('custom-validation', t('REQUIRED_FIELD'), function (value) {
        const {whichPrimaryInsuranceUse} = this.parent;
        const isPatient = whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient;
        const isMy = whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.My;

        const isNotEmpty = value !== null && value !== undefined && value.trim() !== '';

        if (isPatient) {
          if (isNotEmpty) {
            return true;
          }
          if (!isNotEmpty && checkIfTheInsuranceFieldsWasField.call(this)) {
            return false;
          }
        }

        if (isMy) {
          return true;
        }

        return true;
      }),
    guarantorLastName: Yup.string()
      .nullable()
      .test('custom-validation-lastName', t('REQUIRED_FIELD'), function (value) {
        const {whichPrimaryInsuranceUse} = this.parent;
        const isPatient = whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient;
        const isMy = whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.My;

        const isNotEmpty = value !== null && value !== undefined && value.trim() !== '';

        if (isPatient) {
          if (isNotEmpty) {
            return true;
          }
          if (!isNotEmpty && checkIfTheInsuranceFieldsWasField.call(this)) {
            return false;
          }
        }

        if (isMy) {
          return true;
        }

        return true;
      }),
    guarantorBirthDate: Yup.string()
      .nullable()
      .test('is-guarantorBirthDate', t('REQUIRED_FIELD'), function (value) {
        if (this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient && value) {
          return true;
        } else if (
          this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient &&
          !value &&
          checkIfTheInsuranceFieldsWasField.call(this)
        ) {
          return false;
        } else if (this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.My) {
          return true;
        }
        return true;
      })
      .test('is-guarantorBirthDate', t('REQUIRED_FIELD'), function (value) {
        if (!value) return true;
        const birthDate = new Date(value);
        return differenceInYears(new Date(), birthDate) >= 18;
      }),
    guarantorBirthSex: Yup.string()
      .nullable()
      .test('is-guarantorBirthSex', t('REQUIRED_FIELD'), function (value) {
        if (this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient && value) {
          return true;
        } else if (
          this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient &&
          !value &&
          checkIfTheInsuranceFieldsWasField.call(this)
        ) {
          return false;
        } else if (this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.My) {
          return true;
        }
        return true;
      }),
    guarantorSsnTail: Yup.string()
      .nullable()
      .test(
        'min-guarantorSsnTail-lenth',
        t('REQUIRED_FIELD'),
        (val) => !val || val.length >= 4,
      ),
    guarantorCity: Yup.string()
      .nullable()
      .test('custom-validation', t('REQUIRED_FIELD'), function (value) {
        const {whichPrimaryInsuranceUse} = this.parent;
        const isPatient = whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient;
        const isMy = whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.My;

        const isNotEmpty = value !== null && value !== undefined && value.trim() !== '';

        if (isPatient) {
          if (isNotEmpty) {
            return true;
          }
          if (!isNotEmpty && checkIfTheInsuranceFieldsWasField.call(this)) {
            return false;
          }
        }

        if (isMy) {
          return true;
        }

        return true;
      }),
    guarantorState: Yup.string()
      .nullable()
      .test('is-guarantorState', t('REQUIRED_FIELD'), function (value) {
        if (this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient && value) {
          return true;
        } else if (
          this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient &&
          !value &&
          checkIfTheInsuranceFieldsWasField.call(this)
        ) {
          return false;
        } else if (this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.My) {
          return true;
        }
        return true;
      }),
    relationShipType: Yup.string().nullable().required(t('REQUIRED_FIELD')),
    email: Yup.string()
      .nullable()
      .test(
        'is this email already used in a profile',
        t('ENTER_A_NEW_EMAIL_ADDRESS'),
        function (value) {
          if (!value) return true;
          const normalizedValue = validator.normalizeEmail(value) ?? '';
          if (account?.email === normalizedValue) {
            return false;
          }
          return true;
        },
      )
    // eslint-disable-next-line no-useless-escape
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, t('ENTER_A_VALID_EMAIL')),

    firstName: Yup.string()
      .nullable()
      .required(t('REQUIRED_FIELD'))
      .test(
        'notEmpty',
        t('PLEASE_ENTER_FIRST_NAME'),
        function (value) {
          return value !== null && value !== undefined && value.trim() !== '';
        },
      ),
    lastName: Yup.string()
      .nullable()
      .required(t('REQUIRED_FIELD'))
      .test(
        'notEmpty',
        t('PLEASE_ENTER_LAST_NAME'),
        function (value) {
          return value !== null && value !== undefined && value.trim() !== '';
        },
      ),
    birthDate: Yup.string().nullable().required(t('REQUIRED_FIELD')),
    birthSex: Yup.string().nullable().required(t('REQUIRED_FIELD')),
    gender: Yup.string().nullable().notRequired(),
    phone: Yup.string()
      .nullable()
      .when('birthDate', (birthDate) =>
        checkIsPeroson18OrOlder(birthDate)
          ? phoneValidator(t, account?.phone || '')
          : phoneNotRequiredValidator(t, account?.phone || ''),
      ),
    endDate: Yup.string()
      .nullable()
      .test('is-insuranceEndDate', t('DATE_MUST_BE_GREATER_THAN_TODAY'), function (value) {
        if (
          this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient &&
          value &&
          !compareDatesWithToday(value, false, true)
        ) {
          return false;
        } else if (
          this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient &&
          !value &&
          !!compareDatesWithToday(value, false, true) &&
          checkIfTheInsuranceFieldsWasField.call(this)
        ) {
          return false;
        } else if (this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.My) {
          return true;
        }
        return true;
      }),
    policyId: Yup.string()
      .nullable()
      .test('is-insurancePolicyId', t('REQUIRED_FIELD'), function (value) {
        if (this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient && value) {
          return true;
        } else if (
          this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient &&
          !value &&
          checkIfTheInsuranceFieldsWasField.call(this)
        ) {
          return false;
        } else if (this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.My) {
          return true;
        }
        return true;
      }),
    insurancePlan: Yup.string()
      .nullable()
      .test('is-insurancePlan', t('REQUIRED_FIELD'), function (value) {
        if (this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient && value) {
          return true;
        } else if (
          this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient &&
          !value &&
          checkIfTheInsuranceFieldsWasField.call(this)
        ) {
          return false;
        } else if (this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.My) {
          return true;
        }
        return true;
      }),
    insuranceCarrier: Yup.string()
      .nullable()
      .test('is-insuranceCarrier', t('REQUIRED_FIELD'), function (value) {
        if (this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient && value) {
          return true;
        } else if (
          this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient &&
          !value &&
          checkIfTheInsuranceFieldsWasField.call(this)
        ) {
          return false;
        } else if (this.parent.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.My) {
          return true;
        }
        return true;
      }),
  });
};

export const useInsuranceState = () => {
  const [selectedCarrier, setSelectedCarrier] = useState('');
  const [insurancePlans, setInsurancePlans] = useState<InsurancePlansList[] | null>(null);
  const [searchPrimaryText, setSearchPrimaryText] = useState('');
  const [insuranceCarrierId, setInsuranceCarrierId] = useState('');
  const [showInsuranceErrorModal, setShowInsuranceErrorModal] = useState(false);
  const {getInsuranceCarriers} = useInsuranceCarriers();
  const {insurancePlans: plans} = useInsurancePlans(insuranceCarrierId);

  const {insuranceCarriers, isInsurancePlansEmpty} = useAppSelector((state) => state.insurance);

  useEffect(() => {
    if (!selectedCarrier || !plans) {
      setInsurancePlans(null);
      return;
    }

    if (plans.length > 0) {
      setInsurancePlans(plans);
      setShowInsuranceErrorModal(false);
      return;
    }

    if (plans.length === 0 && isInsurancePlansEmpty) {
      setShowInsuranceErrorModal(true);
      setInsurancePlans(null);
    }
  }, [plans, selectedCarrier, isInsurancePlansEmpty, setShowInsuranceErrorModal]);

  useEffect(() => {
    const selectedInsuranceCarrier = insuranceCarriers?.find(
      (carrier) => carrier.name.toLocaleLowerCase() === selectedCarrier.toLocaleLowerCase(),
    );
    if (selectedInsuranceCarrier) setInsuranceCarrierId(selectedInsuranceCarrier?.id);
  }, [selectedCarrier, insuranceCarriers]);

  useEffect(() => {
    if (searchPrimaryText) {
      getInsuranceCarriers(searchPrimaryText);
      setInsurancePlans(null);
      setInsuranceCarrierId('');
    }
  }, [getInsuranceCarriers, searchPrimaryText]);

  return {
    selectedCarrier,
    setSelectedCarrier,
    insurancePlans,
    setInsurancePlans,
    searchPrimaryText,
    setSearchPrimaryText,
    insuranceCarrierId,
    setInsuranceCarrierId,
    showInsuranceErrorModal,
    setShowInsuranceErrorModal,
    isInsurancePlansEmpty,
  };
};
