import {Box, useTheme} from '@mui/material';
import {EmptySearch} from '@src/components';
import {Cell} from '@src/pages/Dynamics/Board/TableComponents';
import {generateRefText} from '@src/pages/Dynamics/Dynamics.helper';
import {Table as NewTable} from '@src/shared/ui/Table/Table';
import {getCriticalColor} from '@src/shared/utils/results';
import {DynamicTestTransformed} from '@src/store/results/helpers';
import {createColumnHelper} from '@tanstack/react-table';
import {useTranslate} from 'i18n/useTranslate';
import {FC, useMemo} from 'react';
import {ResultDynamicsTableProps} from './types';
import {DateTimeFormat, formatDate} from '@src/components/DateFormat';

import {sx} from './styles';

export const ResultDynamicsTable: FC<ResultDynamicsTableProps> = ({dynamicsWithTests, isLoading}) => {
  const {t} = useTranslate('details');
  const columnHelper = createColumnHelper<DynamicTestTransformed>();
  const theme = useTheme();

  const columns = useMemo(() => {
    const baseColumns = [
      columnHelper.accessor('testName', {
        id: 'testName',
        header: t('TEST'),
        minSize: 300,
        maxSize: 300,
        enableSorting: false,
      }),
      columnHelper.accessor('code', {
        id: 'units',
        header: t('UNITS'),
        minSize: 150,
        maxSize: 150,
        enableSorting: false,
        cell: (info) => {
          const {row} = info;
          const units = row.original.resultLaboratoryAnalyses[0]?.unit;
          return units;
        },
      }),
      columnHelper.accessor('refText', {
        id: 'reference',
        header: t('REFERENCE_RANGE'),
        minSize: 200,
        maxSize: 200,
        enableSorting: false,
        cell: (info) => {
          const {row} = info;
          const refMax = row.original.refMax;
          const refMin = row.original.refMin;
          const refText = row.original.refText;
          return generateRefText({refMax, refMin, refText});
        },
      }),
    ];

    const dynamicColumns = () => {
      // TODO: [tech-debt] - Refactor this section of the code to improve efficiency, maintainability and clarity.
      if (!dynamicsWithTests || dynamicsWithTests.length === 0) {
        return [];
      }

      const cachedDateTimes = new Set<string>();
      const columnData: Array<{ roundedDateTime: string; formattedDateTime: string; dateObject: Date }> = [];

      dynamicsWithTests.forEach((item) => {
        item.resultLaboratoryAnalyses.forEach((analysis) => {
          const roundedDateTime = formatDate(analysis.biomaterialSamplingDate, DateTimeFormat.ISODateWithHours);
          const formattedDateTime = formatDate(analysis.biomaterialSamplingDate, DateTimeFormat.USDateSlashed);

          if (!roundedDateTime || !formattedDateTime) return;

            if (!cachedDateTimes.has(roundedDateTime)) {
              const hasNonNullValue = dynamicsWithTests.some((test) =>
                test.resultLaboratoryAnalyses.some(
                  (r: { biomaterialSamplingDate: string; value?: string | null }) =>
                    formatDate(r.biomaterialSamplingDate, DateTimeFormat.ISODateWithHours) === roundedDateTime && r.value != null,
                ),
              );

              if (!hasNonNullValue) {
                return;
              }

              cachedDateTimes.add(roundedDateTime);
              columnData.push({
                roundedDateTime,
                formattedDateTime,
                dateObject: new Date(analysis.biomaterialSamplingDate),
              });
            }
        });
      });

      columnData.sort((a, b) => a.dateObject.getTime() - b.dateObject.getTime());

      return columnData.map(({ roundedDateTime, formattedDateTime }) =>
        columnHelper.accessor(
          (row) =>
            row.resultLaboratoryAnalyses.find(
              (r: { biomaterialSamplingDate: string }) =>
                formatDate(r.biomaterialSamplingDate, DateTimeFormat.ISODateWithHours) === roundedDateTime,
            )?.value ?? '',
          {
            id: roundedDateTime,
            minSize: 160,
            maxSize: 160,
            header: formattedDateTime,
            enableSorting: false,
            cell: ({ row, column }) => {
              const columnDateTime = column.id;

              const matchingAnalysis = row.original.resultLaboratoryAnalyses.find(
                (analysis: { biomaterialSamplingDate: string }) =>
                  formatDate(analysis.biomaterialSamplingDate, DateTimeFormat.ISODateWithHours) === columnDateTime,
              );

              if (!matchingAnalysis) return null;

              const { value, comment, isCriticalRefMark } = matchingAnalysis;
              const criticalColor = getCriticalColor(matchingAnalysis, theme);

              return (
                <Cell
                  value={value}
                  comment={comment}
                  color={criticalColor}
                  isCriticalRefMark={isCriticalRefMark}
                />
              );
            },
          },
        ),
      );
    };

    return [...baseColumns, ...dynamicColumns()];
  }, [dynamicsWithTests]);

  if (isLoading || dynamicsWithTests.length === 0) {
    return (
      <Box sx={sx.emptySearch}>
        <EmptySearch isLoading={isLoading} isNew />
      </Box>
    );
  }

  return (
    <NewTable
      columns={columns}
      data={dynamicsWithTests}
    />
  );
};
