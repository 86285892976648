import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: {
    pb: {
      xs: 18,
      sm: 40,
      lg: 24,
    },
    overflow: 'auto',
  },
  requestAvatar: {
    width: 40,
    height: 40,
    mr: 12,
    backgroundColor: t => t.palette.secondary[400],
    color: t => t.palette.common.white,
  },
  personType: {
    minHeight: 20,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    typography: {xs: '12_16_500', sm: '14_18_500'},
    mt: {xs: 0, sm: 0},
    mb: {xs: 0, sm: 7, lg: 0},
  },
  personName: {
    cursor: 'pointer',
    typography: {xs: '16_20_700', sm: '18_24_700'},
  },
  widthLimitContainer: {
    minWidth: '10%',
    mt: {xs: 3, sm: 0},
  },
  bthContainer: {
    flexDirection: 'row',
    justifyContent: 'end',
    gap: 18,
  },
  date: {
    mb: 12,
    color: t => t.palette.grey[500],
  },
  button: {
    p: '0px !important',
    fontWeight: 600,
    minWidth: 'inherit',
  },
  emptyRequests: (t) => ({
    p: {
      xs: '0px 18px 18px 18px',
      sm: '0px 18px',
      lg: '0px 24px',
    },
    typography: {
      xs: '12_16_500',
      sm: '14_18_500',
    },
    color: {
      xs: t.palette.grey[500],
      sm: t.palette.grey[600],
    },
  }),
};
