import {Tabs, styled, Theme, SxProps} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  buttonGroups: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    p: 24,
  },
  checkExpired: (t) => ({
    display: 'flex',
    gap: 18,
    mr: {lg: 36},
    [t.breakpoints.between('sm', 'lg')]: {
      height: 24,
    },
  }),
  buttonGroupsMobile: {
    p: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  wrapper: {
    backgroundColor: t => t.palette.common.white,
    p: 18,
    flexGrow: 1,
    gap: {
      xs: 24,
      sm: 36,
    },
  },
  boxContainer: {
    display: 'flex',
    alignItems: {
      xs: 'center',
      sm: 'flex-start',
      lg: 'center',
    },
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: {
      sm: 'column',
      lg: 'inherit',
    },
    gap: {
      sm: 12,
      lg: 28,
    },
    mt: {
      lg: 4,
    },
  },
  emptyAccessesText: (t: Theme) => ({
    typography: {xs: '14_18_500'},
    color: t.palette.grey[600],
    width: 'fit-content',
    p: {
      xs: '0px 18px',
      sm: '0px 24px',
    },
  }),
  wBoxContainer: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    p: '16px !important',
  },
  container: (t) => ({
    background: t.palette.common.white,
    display: 'flex',
    height: 'calc(100vh - 200px)',
    overflow: 'hidden',
    [t.breakpoints.between('sm', 'lg')]: {
      backgroundColor: t.palette.common.white,
      flexGrow: 1,
      gap: 0,
      height: 'calc(100vh - 198px)',
      overflow: 'hidden',
    },
  }),
  content: {
    width: '100%',
  },
  contentContainer: {
    overflow: 'auto',
    overflowX: 'hidden',
    flexGrow: 1,
  },
  fetchingBlock: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },
};

export const StyledTabs = styled(Tabs)(({theme}) => ({
  padding: 0,
  '.MuiTabs-flexContainer': {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    '.Mui-selected': {
      color: 'unset !important',
    },
    '.MuiButtonBase-root': {
      padding: 18,
      justifyContent: 'space-between',
      minHeight: 62,
      border: '1px solid',
      borderColor: theme.palette.secondary[300],
      fontSize: 22,
      fontWeight: 500,
      lineHeight: '26px',
      color: theme.palette.grey[800],
      svg: {
        path: {
          fill: theme.palette.grey[400],
        },
      },
    },
  },
  '.MuiTabs-indicator': {
    backgroundColor: 'unset',
  },
}));
