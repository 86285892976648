import {Typography, Stack} from '@mui/material';
import {Box} from '@mui/system';
import {useTranslate} from '@src/i18n/useTranslate';
import {paletteColors} from '@src/theme';
import {FC} from 'react';

import {PulseLoader} from '../PulseLoader';

import {NewStackWrapper, sx} from './styled';
import {IEmptySearchProps} from './types';

export const EmptySearch: FC<IEmptySearchProps> = ({isLoading, isNew, isConnections, isResults, isDynamics, isDetails}) => {
  const {t} = useTranslate('common');
  if (isNew) {
    return (
      <NewStackWrapper
        isLoading={isLoading}
        isConnections={isConnections}
        isResults={isResults}
        isDynamics={isDynamics}
        isDetails={isDetails}
      >
        {
          isLoading
            ? (
              <Box sx={sx.loaderContainer}>
                <PulseLoader loading={true}/>
              </Box>
            )
            : (
              <>
                <Typography variant='14_18_500' color={paletteColors.grey[400]}>
                  {t('NO_SUITABLE_RESULTS')}
                </Typography>
              </>
            )
        }
      </NewStackWrapper>
    );
  }

  return (
    <Stack
      sx={sx.stackWrapper}
    >
      {isLoading
        ? <PulseLoader loading={true} />
        : (
          <Typography typography={'14_18_500'}>
            {t('NO_SUITABLE_RESULTS')}
          </Typography>
        )
      }
    </Stack>
  );
};
