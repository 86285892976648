import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  loverBlockContainer: (t) => ({
    position: 'relative',
    p: 24,
    [t.breakpoints.up('lg')]: {
      borderTop: '1px solid',
      borderColor: t.palette.secondary[300],
    },
    [t.breakpoints.down('lg')]: {
      width: 'auto',
      p: {xs: '36px 0 0', sm: '48px 0 0'},
    },
  }),
  upperBlockContainer: (t) => ({
    flexGrow: 1,
    p: {xs: 0, lg: 24},
    [t.breakpoints.between('sm', 'lg')]: {
      width: 'auto',
    },
  }),
  listContainer: {
    mb: 36,
  },
  mainTitle: {
    typography: {
      xs: '18_24_500',
      sm: '20_24_500',
      lg: '24_34_500',
    },
    mb: {xs: 18, sm: 24, lg: 16},
  },
  boxInfoContainer: (t) => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: {xs: 4, sm: 10},
    color: t.palette.grey[600],
    svg: {
      flexShrink: 0,
      path: {
        fill: 'currentColor',
      },
    },
  }),
  notSpecifiedText: (t) => ({
    color: t.palette.grey[600],
    typography: {xs: '12_16_500', sm: '14_18_500'},
    mt: {lg: 8},
  }),
  addressContainer: {
    flexDirection: 'initial',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  phoneAndEmailTitleMain: {
    pb: {xs: 12, sm: 18, lg: 30},
    typography: {xs: '16_20_500', sm: '18_24_500'},
  },
  phoneAndEmailTitleOptional: {
    typography: {xs: '16_20_500', sm: '18_24_500'},
    pb: {xs: 12, sm: 18, lg: 30},
  },
  editInfoText: {
    mt: {xs: 1, sm: 0},
    typography: {xs: '12_16_500', sm: '14_18_500'},
  },
  divider: (t) => ({
    position: 'absolute',
    left: {xs: -18, sm: -24},
    right: {xs: -18, sm: -24},
    top: {xs: 18, sm: 24},
    borderColor: t.palette.secondary[300],
  }),
};
