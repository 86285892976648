import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  successButton: {
    backgroundColor: (t) => t.palette.common.white,
  },
  dialogActions: (t) => ({
    backgroundColor: t.palette.success.main,
    margin: 0,
    p: '16px 24px',
    justifyContent: 'flex-end !important',
    alignItems: 'end',
  })
};
