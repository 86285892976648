import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  mainBlock: (t: Theme) => ({
    padding: '40px 54px',
    width: '600px',
    marginTop: '90px',
    marginBottom: '90px',
    maxHeight: '1000px',
    [t.breakpoints.down('md')]: {
      justifyContent: 'center',
      alignContent: 'center',
      marginTop: '40px',
      marginBottom: '40px',
    },
    [t.breakpoints.down('sm')]: {
      margin: '25px 10px',
    },
    [t.breakpoints.only('xs')]: {
      marginTop: '110px',
      marginBottom: '40px',
      padding: '24px',
      height: '100%',
      flexDirection: 'initial'
    },
  }),
  saveBtn: {
    width: '120px'
  },
  title: {
    typography: {
      xs: '22_26_700',
      sm: '32_36_700',
    },
    flexGrow: 1,
    alignSelf: 'center',
  },
  topblock: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: {
      sm: 12,
    },
  },
  block: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
  },
  signIn: {
    flexGrow: 1,
    alignSelf: {
      xs: 'flex-start',
      sm: 'flex-end',
    },
    mb: {
      xs: 24,
    },
  },
  viewer: {
    height: 'calc(100vh - 350px)',
    maxHeight: '900px',
  },
};
