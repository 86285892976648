import {Box, Stack, Typography} from '@mui/material';
import {Panel, Product} from '@src/api';
import {
  Breadcrumbs,
  EmptySearch,
  ITableProps,
  Link,
  Text,
  RowInformation,
  Table,
  PageHeader,
} from '@src/components';
import Grid from '@src/components/Grid';
import {PulseLoader} from '@src/components/PulseLoader';
import {SendResult} from '@src/components/SendResult';
import {HealthCaseSelector} from '@src/features/HealthCaseSelector';
import {useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as DownloadIcon} from '@src/shared/assets/icons/download.svg';
import {ReactComponent as AddIcon} from '@src/shared/assets/icons/plus_circle.svg';
import {envs} from '@src/shared/constants/envs';
import {useColumnsNumber, useSidebarClassObserver} from '@src/shared/hooks';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {getAge, getFullName, dateFormatted} from '@src/shared/utils';
import {getResultTitle} from '@src/shared/utils/getResultTitle';
import {useAppSelector} from '@src/store';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {useEmailForm, useResultDetail} from '@src/store/results/hooks';
import {resultsActions} from '@src/store/results/slice';
import {Steps} from '@src/types';
import {BoxBody, BoxHeader, WhiteBox, BoxPaddings} from 'components/WhiteBox/WhiteBox';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {Button} from 'ui-kit';

import {ExpandableTableRowContainer} from './ExpandableTableRowContainer/ExpandableTableRowContainer';
import {UpdateCode} from './ExpandableTableRowContainer/types';
import {defaultColumns, customColumns} from './constants';
import {getBreakpoints} from './helpers';
import {sx} from './styles';
import {useHeader} from './utils/useHeader';

export const Details: React.FC = () => {
  const {t} = useTranslate('details');
  const [testCodes, setTestCodes] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const {
    mobile,
    tablet,
    tabletPortrait,
    desktop: isDesktop,
    lg: isDesktopLg,
  } = useMQuery();
  const [allCodeSelect, setAllCodeSelect] = useState<string[]>([]);
  const [selectHeaderCheckbox, setSelectHeaderCheckbox] = useState(false);
  const {isSidebarOpen} = useSidebarClassObserver();
  const containerHealcthCases = useRef<HTMLDivElement | null>(null);
  const isSaving = useAppSelector((state) => state.healthCases.isSaving);

  const {resultId, companyId} = useParams();
  const navigate = useNavigate();
  const {submit} = useEmailForm();

  const dispatch = useDispatch();

  const {step} = useAppSelector((state) => state.results);

  const breakpoints = getBreakpoints();

  const {colsNumberLg, colsNumberMd, containerRef} = useColumnsNumber({breakpoints, defaultColumns, customColumns});

  const closeSendResultModal = () => {
    void dispatch(resultsActions.setStep({step: Steps.INIT}));
  };

  const {
    data: result,
    isFetching: resultLoading,
    isLoading: loading,
    isNotFound,
    isForbidden,
    refetch,
  } = useResultDetail({resultId, companyId});

  useEffect(() => {
    const allTestCodesInPanels = result?.panels?.map((panel) =>
      panel.products?.flatMap((product) => product.tests.flatMap((test) => test.loinc)),
    );
    const allTestCodesProducts = result?.products?.map((product) =>
      product.tests.flatMap((test) => test.loinc),
    );

    const allTestCodes = [
      ...(allTestCodesInPanels ?? []),
      ...(allTestCodesProducts ?? []),
    ].flat() as string[];

    if (allTestCodes) {
      setAllCodeSelect(allTestCodes);
    }
  }, [result]);

  useEffect(() => {
    if (isSaving && resultId) {
      refetch();
    }
  }, [isSaving, resultId, refetch]);

  useEffect(() => {
    if (allCodeSelect.length > 0 && testCodes.length === allCodeSelect.length) {
      setSelectHeaderCheckbox(true);
      setSelectAll(true);
    } else {
      setSelectHeaderCheckbox(false);
      setSelectAll(false);
    }
  }, [testCodes, allCodeSelect]);

  useEffect(() => {
    if (!resultId) return;
    dispatch(healthCasesActions.toggleResultsSelection(resultId));

    return () => {
      dispatch(healthCasesActions.clearSelectedResults());
    };
  }, [resultId]);

  const goToDynamics = () => {
    const params = encodeURI(JSON.stringify([...new Set(testCodes)]));
    navigate(`dynamics/${params}`);
  };

  const changeCodesForDynamics: UpdateCode = (item, checked, flag) => {
    if (typeof item === 'string') {
      if (checked) {
        setTestCodes((prev) => [...prev, item]);
      } else {
        const findCodeIndex = testCodes.indexOf(item);
        setTestCodes((prev) => {
          prev.splice(findCodeIndex, 1);
          return [...prev];
        });
      }
    } else {
      const tests: string[] = flag ? item.flat().map((t) => t.loinc) : item.map((t) => t.loinc);

      if (checked) {
        setTestCodes((prev) => [...new Set([...prev, ...tests])]);
      } else {
        setTestCodes((prev) => {
          return prev.filter((t) => {
            const findIndex = tests.indexOf(t);
            if (findIndex >= 0) {
              tests.splice(findIndex, 1);
            }
            return findIndex < 0;
          });
        });
      }
    }
  };

  const handleSendResult = (email: string) => {
    submit({resultId, email});
  };

  const changeAllTests = (_: any, checked: any) => {
    if (checked) {
      setSelectAll(true);
      setTestCodes([...allCodeSelect]);
    } else {
      setSelectAll(false);
      setTestCodes([]);
    }
  };

  const {HEADER_LIST} = useHeader(changeAllTests, selectHeaderCheckbox);

  const desktopGridColumns = '348px auto 100px 160px 140px';
  const desktopLgGridColumns = '348px minmax(211px, 1fr) 100px 160px 140px';
  const desktopLgOpenSidebarGridColumns = '285px minmax(180px, 1fr) 100px 160px 140px';
  const tabletGridColumns = '28.79% 26.77% 5.55% 12.59% 15.65%';
  const mobileGridColumns = '50px 1fr';

  const gridTemplateColumns = useMemo(() => {
    if (tablet) {
      return tabletGridColumns;
    }

    if (mobile) {
      return mobileGridColumns;
    }

    if (isDesktopLg) {
      return !isSidebarOpen ? desktopLgGridColumns : desktopLgOpenSidebarGridColumns;
    }
    return desktopGridColumns;
  }, [tablet, mobile, isDesktopLg, isSidebarOpen]);

  if (!loading && (isNotFound || isForbidden)) {
    const route = isNotFound ? ROUTERS_PATH.NOT_FOUND : ROUTERS_PATH.FORBIDDEN;
    navigate(route);
    return null;
  }

  if (!result || loading) {
    return (
      <Stack sx={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
        <PulseLoader loading={loading} />
      </Stack>
    );
  }

  const {orderDetails, patient, products, panels, healthCases} = result;
  const {birthDate, birthSex, firstName, lastName} = patient;
  const {collected, number, provider, reported} = orderDetails;

  const data = [...products, ...panels];
  const tableData = data.length === 0 ? [] : data;

  const downloadResulstLink = `${envs.BASE_API}/api/results/${resultId ?? ''}/fn/download?companyId=${companyId ?? ''}`;
  const nameTypography = isDesktop ? '16_24_700' : '14_18_700';
  const valueTypography = isDesktop ? '16_24_500' : '14_18_500';
  const columnSpacing = isDesktop ? 18 : 0;

  const birthDateValue = birthDate ? `${dateFormatted(birthDate) || ''} (${getAge(birthDate)} ${t('YEARS')})` : null;

  const caclWBoxCollapsible = () => {
    if (tablet) {
      return containerRef.current && containerRef.current?.offsetWidth < breakpoints.md;
    }
    if (isDesktop) {
      return containerRef.current && containerRef.current?.offsetWidth < breakpoints.lg;
    }
    if (mobile) {
      return true;
    }
  };

  const isCollapsible = caclWBoxCollapsible() ?? false;

  return (
    <Box sx={sx.mainContainer}>
      <Stack gap={24}>
        <Breadcrumbs>
          <Link to={ROUTERS_PATH.RESULTS}>{t('RESULTS')}</Link>
          <Text sx={sx.breadcrumbsText}>{getResultTitle(orderDetails)}</Text>
        </Breadcrumbs>
        <PageHeader
          itemAction={
            <SendResult onSubmit={handleSendResult} step={step} close={closeSendResultModal} />
          }
        >
          {getResultTitle(orderDetails)}
        </PageHeader>
      </Stack>
      <Grid ref={containerRef} container spacing={{xs: 18, sm: 24, lg: 36}}>
        <Grid
          mt={mobile ? 6 : 0}
          xs={12}
          md={colsNumberMd}
          lg={colsNumberLg}
          display={'flex'}>
          <WhiteBox
            notApplySx
            collapsible={isCollapsible}
            sx={sx.wBoxContent}
            id={'boxOne'}
            headerSx={sx.headerSx}
            hasIconBox
            header={isCollapsible ? t('PATIENT_DETAILS') : ''}
          >
            {!isCollapsible && <BoxHeader sx={sx.boxHeader}>{t('PATIENT_DETAILS')}</BoxHeader>}
            <BoxBody>
              <Grid container rowSpacing={0} columnSpacing={columnSpacing}>
                <Grid xs={12}>
                  <Grid>
                    <RowInformation
                      variant={nameTypography}
                      valueTypographyVariant={valueTypography}
                      sx={sx.rowInformation}
                      noMargin={isDesktop}
                      tooltip={isDesktop}
                      value={firstName}
                      noWrap={!isDesktop}
                      name={t('FIRST_NAME')} />
                    <RowInformation
                      variant={nameTypography}
                      valueTypographyVariant={valueTypography}
                      sx={sx.rowInformation}
                      noMargin={isDesktop}
                      tooltip={isDesktop}
                      value={lastName}
                      noWrap={!isDesktop}
                      name={t('LAST_NAME')} />
                  </Grid>
                </Grid>
                <Grid xs={12}>
                  <Grid>
                    <RowInformation
                      variant={nameTypography}
                      valueTypographyVariant={valueTypography}
                      sx={sx.rowInformation}
                      noMargin={isDesktop}
                      tooltip={isDesktop}
                      noWrap={!isDesktop}
                      value={birthDateValue}
                      name={t('DATE_OF_BIRTH')}
                    />
                    <RowInformation
                      variant={nameTypography}
                      valueTypographyVariant={valueTypography}
                      noMargin
                      noWrap={!isDesktop}
                      tooltip={isDesktop}
                      value={t(birthSex)}
                      name={t('BIRTH_SEX')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </BoxBody>
          </WhiteBox>
        </Grid>
        <Grid
          xs={12}
          md={colsNumberMd}
          lg={colsNumberLg}
          display={'flex'}>
          <WhiteBox
            notApplySx
            sx={sx.wBoxContent}
            collapsible={isCollapsible}
            id={'boxTwo'}
            headerSx={sx.headerSx}
            hasIconBox
            header={isCollapsible ? t('ORDER_DETAILS') : ''}
          >
            {!isCollapsible && <BoxHeader sx={sx.boxHeader}>{t('ORDER_DETAILS')}</BoxHeader>}
            <BoxBody>
              <Grid container rowSpacing={0} columnSpacing={columnSpacing}>
                <Grid xs={12}>
                  <Grid>
                    <RowInformation
                      variant={nameTypography}
                      valueTypographyVariant={valueTypography}
                      sx={sx.rowInformation}
                      noMargin={isDesktop}
                      tooltip={isDesktop}
                      noWrap={!isDesktop}
                      value={number.toString()}
                      name={t('ORDER_ID')}
                    />
                    <RowInformation
                      variant={nameTypography}
                      valueTypographyVariant={valueTypography}
                      sx={sx.rowInformation}
                      noMargin={isDesktop}
                      tooltip={isDesktop}
                      noWrap={!isDesktop}
                      value={dateFormatted(collected)}
                      name={t('COLLECTION_DATE')}
                    />
                  </Grid>
                </Grid>
                <Grid xs={12}>
                  <Grid>
                    <RowInformation
                      variant={nameTypography}
                      valueTypographyVariant={valueTypography}
                      sx={sx.rowInformation}
                      noMargin={isDesktop}
                      tooltip={isDesktop}
                      noWrap={!isDesktop}
                      value={dateFormatted(reported)}
                      name={t('REPORTED_DATE')}
                    />
                    <RowInformation
                      variant={nameTypography}
                      valueTypographyVariant={valueTypography}
                      noMargin
                      noWrap={!isDesktop}
                      tooltip={isDesktop}
                      value={getFullName(provider)}
                      name={t('ORDERING_PROVIDER')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </BoxBody>
          </WhiteBox>
        </Grid>
        <Grid
          xs={12}
          md={colsNumberMd}
          lg={colsNumberLg}
          display={'flex'}>
          <WhiteBox
            notApplySx
            sx={sx.wBoxContent}
            collapsible={isCollapsible}
            id={'boxThree'}
            headerSx={sx.headerSx}
            hasIconBox
            header={isCollapsible ? t('HEALTH_CASES') : ''}
          >
            {!isCollapsible && <BoxHeader sx={sx.boxHeader}>{t('HEALTH_CASES')}</BoxHeader>}
            <Box ref={containerHealcthCases} sx={sx.healthCasesContainer}>
              {healthCases.map((healthCase, key) => (
                <Link sx={sx.healthCase} to={`/health-cases/${healthCase.id}`} key={key}>{healthCase.name}</Link>
              ))}
              <HealthCaseSelector
                isText
                containerRef={containerHealcthCases}
                healthCases={healthCases}
                isDetails
              >
                <Stack gap={4} flexDirection={'row'}>
                  <AddIcon />
                  <Typography variant='14_18_500'>
                    {t('ADD')}
                  </Typography>
                </Stack>
              </HealthCaseSelector>
            </Box>
          </WhiteBox>
        </Grid>
      </Grid>
      <WhiteBox noPaddings sx={sx.wBoxContainer}>
        <BoxBody>
          <BoxPaddings sx={sx.boxPaddings}>
            <Box sx={sx.boxContainer}>
              <BoxHeader>{t('RESULTS')}</BoxHeader>
              {!mobile && (
                <Box sx={sx.bthContainer}>
                  <Button
                    variant="text"
                    startIcon={<DownloadIcon />}
                    href={downloadResulstLink}
                    target="_blank"
                  >
                    {t('DOWNLOAD_RESULTS')}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={goToDynamics}
                    disabled={testCodes.length === 0}
                  >
                    {t('DYNAMICS_OF_RESULTS')}
                  </Button>
                </Box>
              )}
            </Box>
          </BoxPaddings>
          <Table
            paddingLeft={isDesktop ? '28px' : '18px'}
            header={!mobile && !tabletPortrait}
            keyIndicator="code"
            data={tableData as ITableProps<Product & Panel>['data']}
            gridTemplateColumns={gridTemplateColumns}
            headerList={HEADER_LIST}
            rowComponent={{
              component: ExpandableTableRowContainer,
              props: {
                changeCodesForDynamics,
                testCodes,
                selectAll,
                selectRest: selectHeaderCheckbox,
                gridTemplateColumns,
              },
            }}
            loading={resultLoading}
            emptyData={<EmptySearch isLoading={loading} isNew isDetails/>}
            minHeight={1565}
            isModifiedScroll
          />
        </BoxBody>
        {mobile && (
          <Box sx={sx.buttonsContainer}>
            <Button
              fullWidth
              variant={'contained'}
              onClick={goToDynamics}
              disabled={testCodes.length === 0}
            >
              {t('DYNAMICS_OF_RESULTS')}
            </Button>
            <Button
              fullWidth
              variant="outlined"
              href={downloadResulstLink}
              target="_blank"
            >
              {t('DOWNLOAD_RESULTS')}
            </Button>
          </Box>
        )}
      </WhiteBox>
    </Box>
  );
};
