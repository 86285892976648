import {DatePickerRange} from '@components';
import {ListItemText, Stack, Typography, SelectChangeEvent} from '@mui/material';
import {Result} from '@src/api';
import Grid from '@src/components/Grid';
import {MultiSelect} from '@src/components/MultiSelect';
import {BoxPaddings} from '@src/components/WhiteBox';
import {FiltersDialog as FiltersDialogContainer} from '@src/components/fragments/FiltersDialog';
import {dateInInterval} from '@src/pages/Results/Results.helpers';
import {ReactComponent as RefreshIcon} from '@src/shared/assets/icons/refresh.svg';
import {ReactComponent as SettingsIcon} from '@src/shared/assets/images/icons/icon_settings.svg';
import {useMQuery} from '@src/shared/hooks';
import {getFullName} from '@src/shared/utils';
import {useAppDispatch} from '@src/store';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {useResults} from '@src/store/results/hooks';
import {TKeys, useTranslate} from 'i18n/useTranslate';
import {ChangeEvent, FC, useCallback, useEffect, useMemo, useState} from 'react';
import {IconButton, MenuItem} from 'ui-kit';
import {FormSelectControl} from '@src/components/FormSelectControl';
import {initialFilter} from '../ResultsTableDialog/constants';

import {sx} from './styles';
import {ResultsFilterTableProps} from './types';

export const ResultsFilter: FC<ResultsFilterTableProps> = ({
  handleCloseFilter,
  isOpen,
  handleOpenFilter,
}) => {
  const {t, ready} = useTranslate('results');
  const {mobile, tabletPortrait} = useMQuery();
  const onPortrait = mobile || tabletPortrait;
  const {data} = useResults();
  const [filter, setFilter] = useState(initialFilter);
  const dispatch = useAppDispatch();
  const [tempFilter, setTempFilter] = useState(filter);

  const [selectedPhysician, setSelectedPhysician] = useState('');
  const [tempSelectedPhysician, setTempSelectedPhysician] = useState(selectedPhysician);
  const [selectedHealthCase, setSelectedHealthCase] = useState<string[]>([]);
  const [tempSelectedHealthCase, setTempSelectedHealthCase] =
    useState<string[]>(selectedHealthCase);

  const [searchValueHealthCase, setSearchValueHealthCase] = useState('');

  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValueHealthCase(value);
  };

  const handleClearSearchValue = () => {
    setSearchValueHealthCase('')
  };

  useEffect(() => {
    if (data) {
      dispatch(healthCasesActions.setResult(data));
    }
  }, [data]);

  const getPhysicians = useMemo(() => {
    const uniquePhysiciansMap = new Map(data?.map((item) => [item?.physician?.id, item.physician]));

    const uniquePhysicians = [...uniquePhysiciansMap.values()];

    const physiciansOptions = uniquePhysicians.map((physician) => ({
      value: physician?.id || '',
      name: getFullName(physician),
      key: physician?.id || '',
    }));

    return physiciansOptions.filter((item) => item.name && item.value);
  }, [data]);

  const getHealthCases = useMemo(() => {
    const allHealthCases = data?.flatMap((item) => item?.healthCases) || [];

    const uniqueHealthCasesMap = new Map(
      allHealthCases.map((healthCase) => [healthCase?.name, healthCase]),
    );

    const uniqueHealthCases = Array.from(uniqueHealthCasesMap.values());

    return uniqueHealthCases.map((healthCase) => ({
      value: healthCase?.id || '',
      name: healthCase?.name || '',
    }));
  }, [data]);

  const handlePhysicianSelect = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    setTempSelectedPhysician(selectedValue);
    const selectedPhysician = getPhysicians.find((item) => item.name === selectedValue);

    if (selectedPhysician) {
      setTempFilter((prev) => ({
        ...prev,
        physician: {value: selectedPhysician.value, name: selectedPhysician.name},
      }));
    }
  };

  const handleHealthCaseSelect = (value: string[]) => {
    const selectedHealthCases = value
      .map((id) => {
        const healthCase = getHealthCases.find((hc) => hc.value === id);
        return healthCase ? {value: healthCase.value, name: healthCase.name} : null;
      })
      .filter(Boolean) as Array<{ value: string, name: string }>;

    setTempSelectedHealthCase(value);
    setTempFilter((prevFilter) => ({
      ...prevFilter,
      healthCase: selectedHealthCases,
    }));
  };

  const filterByPhysician = (
    resultItem: Result,
    physician: { value: string, name: string } | null,
  ) => {
    if (!physician) return true;
    return resultItem.physician?.id === physician.value;
  };

  const filterByHealthCase = (
    resultItem: Result,
    selectedHealthCases: Array<{ value: string, name: string }> | null,
  ) => {
    if (!selectedHealthCases || selectedHealthCases.length === 0) return true;
    return resultItem.healthCases?.some((hc) =>
      selectedHealthCases.some((selectedCase) => selectedCase.value === hc?.id),
    );
  };

  const filterByCollectionDate = (
    resultItem: Result,
    collectionDateFrom: Date | null,
    collectionDateTo: Date | null,
    filterOpened: boolean,
  ) => {
    if (!filterOpened || (!collectionDateFrom && !collectionDateTo)) return true;
    return dateInInterval(collectionDateFrom, collectionDateTo, resultItem.collected);
  };

  const handleChangeDate = (name: string) => (date: Date | null) => {
    setTempFilter((prev) => ({...prev, [name]: date}));
  };

  const handleClearCollectionDate = () => {
    setTempFilter((prev) => {
      return {
        ...prev,
        collectionDateFrom: null,
        collectionDateTo: null,
      };
    });
  };

  const handleApplyFilter = useCallback(() => {
    const {
      physician,
      healthCase,
      collectionDateFrom,
      collectionDateTo,
    } = tempFilter;

    setFilter(tempFilter);
    setSelectedPhysician(tempSelectedPhysician);
    setSelectedHealthCase(tempSelectedHealthCase);

    const doFiltration = (resultItem: Result) => {
      return (
        filterByPhysician(resultItem, physician) &&
        filterByHealthCase(resultItem, healthCase) &&
        filterByCollectionDate(resultItem, collectionDateFrom, collectionDateTo, isOpen)
      );
    };

    if (data) dispatch(healthCasesActions.setResult(data.filter(doFiltration)));
  }, [data, tempFilter, isOpen]);

  const handleClickApply = () => {
    handleApplyFilter();
    handleCloseFilter();
  };

  const handleClearFilter = () => {
    setSelectedPhysician('');
    setTempSelectedPhysician('');
    setSelectedHealthCase([]);
    setTempSelectedHealthCase([]);
    setTempFilter(initialFilter);
  };

  useEffect(() => {
    if (!isOpen) {
      handleApplyFilter();
    }
  }, [tempFilter]);

  const renderHealthCaseValue = (value: string[]) =>
    value.length > 1 ? value[0] + ` +${value.length - 1}` : value[0] || '';

  if (!ready) return null;

  const renderFilterDialog = () => {
    return (
      <FiltersDialogContainer
        isOpen={isOpen}
        onClose={handleCloseFilter}
        title={t('FILTERS')}
        onApply={handleClickApply}
        onClear={handleClearFilter}
      >
        <Stack gap={24}>
          {onPortrait && (
            <>
              <Grid xs={12} sm={6}>
                <FormSelectControl
                  placeholder={t('SELECT')}
                  label={t('PHYSICIAN')}
                  compact={true}
                  value={tempSelectedPhysician}
                  onChange={handlePhysicianSelect}
                >
                  {getPhysicians.map((option) => (
                    <MenuItem key={option.key} value={option.name}>
                      <ListItemText primary={option.name} />
                    </MenuItem>
                  ))}
                </FormSelectControl>
              </Grid>
              <Grid xs={12} sm={6}>
                <MultiSelect
                  options={getHealthCases}
                  value={tempSelectedHealthCase}
                  onChange={handleHealthCaseSelect}
                  label={t('HEALTH_CASES')}
                  placeholder={t('SELECT')}
                  onChangeSearchValue={handleChangeSearchValue}
                  searchValue={searchValueHealthCase}
                  onClear={handleClearSearchValue}
                  renderValue={renderHealthCaseValue}
                />
              </Grid>
            </>
          )}
          <Stack gap={44}>
            <Stack>
              <Typography variant="14_18_700">{t('COLLECTION_DATE')}</Typography>
              <DatePickerRange
                variant="outlined"
                startDate={tempFilter.collectionDateFrom}
                setStartDate={handleChangeDate('collectionDateFrom')}
                setEndDate={handleChangeDate('collectionDateTo')}
                endDate={tempFilter.collectionDateTo}
                clearDate={handleClearCollectionDate}
                isLabelOutside={false}
                isIconVisible={false}
              />
            </Stack>
          </Stack>
        </Stack>
      </FiltersDialogContainer>
    );
  };

  return (
    <>
      {!onPortrait && (
        <BoxPaddings noBottom noTop sx={sx.boxContainer}>
          <Grid
            container
            gap={36}
            flexGrow={1}
            sx={sx.gridContainer}>
            <Grid
              xs={4.5}
              lg={4.5}
              xl={4.5}
              sx={sx.inputWrapper}>
              <Stack justifyContent={'center'}>
                <Typography variant="14_18_700" width={75}>
                  {t('PHYSICIAN')}
                </Typography>
              </Stack>
              <FormSelectControl
                placeholder={t('SELECT')}
                name={'physician'}
                value={selectedPhysician}
                onChange={handlePhysicianSelect}
              >
                {getPhysicians.map((option) => (
                  <MenuItem key={option.key} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </FormSelectControl>
            </Grid>
            <Grid
              xs={4.5}
              lg={4.5}
              xl={4.5}
              sx={sx.inputWrapper}>
              <MultiSelect
                options={getHealthCases}
                value={selectedHealthCase}
                onChange={handleHealthCaseSelect}
                label={t('HEALTH_CASES')}
                placeholder={t('SELECT')}
                onChangeSearchValue={handleChangeSearchValue}
                searchValue={searchValueHealthCase}
                onClear={handleClearSearchValue}
                renderValue={renderHealthCaseValue}
              />
            </Grid>
            <Stack flexDirection={'row'} gap={12} pt={22}>
              <IconButton color={'secondary-light'} variant={'outlined'} onClick={handleOpenFilter}>
                <SettingsIcon />
              </IconButton>
              <IconButton color={'secondary-light'} onClick={handleClearFilter}>
                <RefreshIcon />
              </IconButton>
            </Stack>
          </Grid>
        </BoxPaddings>
      )}
      {renderFilterDialog()}
    </>
  );
};
