import {Dialog, DialogActions, DialogTitle} from '@components';
import {Box, Stack, Typography} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';
import {useMQuery} from '@src/shared/hooks';
import {MobileDialog} from '@src/shared/ui/MobileDialog/MobileDialog';
import {useAppDispatch} from '@src/store';
import {deleteHealthCase} from '@src/store/healthCases/slice';
import {FC} from 'react';
import {Button} from 'ui-kit';

import {sx} from './styles';
import {DeleteDialogProps} from './types';

export const DeleteDialog: FC<DeleteDialogProps> = ({
  isOpen,
  id,
  onClose,
  afterDeleteAction,
}) => {
  const {t} = useTranslate('healthCases');
  const {mobile} = useMQuery();
  const dispatch = useAppDispatch();

  const handleDelete = () => {
    void dispatch(deleteHealthCase({id}))
      .unwrap()
      .then(() => {
        onClose();
        afterDeleteAction?.();
      })
      .catch(() => {
        onClose();
      });
  };

  return (
    <>
      {mobile && (
        <MobileDialog
          onClose={onClose}
          isOpen={isOpen}
          padding="18px"
        >
          <Stack gap={12} sx={{pb: 24}}>
            <Typography variant={'16_20_700'} color={'grey.800'}>{t('DELETE_HEALTH_CASE')}</Typography>
            <Typography variant={'16_20_500'}>{t('YOU_WANT_DELETE')}</Typography>
            <Typography variant={'16_20_500'}>{t('ATTACHED_RESULTS_AND_DOCUMENTS')}</Typography>
          </Stack>
          <Stack gap={8}>
            <Button onClick={handleDelete}>
              {t('DELETE')}
            </Button>
            <Button variant="outlined" onClick={onClose}>
              {t('CANCEL')}
            </Button>
          </Stack>
        </MobileDialog>
      )}
      {!mobile && (
        <Dialog
          open={isOpen}
          size={'sm'}
          border={'unset'}>
          <DialogTitle onClose={onClose} width='65px'>{t('DELETE_HEALTH_CASE')}</DialogTitle>
          <Box sx={sx.mainDialogContent}>
            <Stack gap={24}>
              <Typography variant={'16_20_500'}>{t('YOU_WANT_DELETE')}</Typography>
              <Typography variant={'16_20_500'}>{t('ATTACHED_RESULTS_AND_DOCUMENTS')}</Typography>
            </Stack>
          </Box>
          <DialogActions sx={sx.btnContainer}>
            <Button
              sx={sx.btn}
              variant={'outlined'}
              color={'secondary'}
              onClick={onClose}>
              {t('CANCEL')}
            </Button>
            <Button sx={sx.btn} color={'secondary'} onClick={handleDelete}>
              {t('DELETE')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
