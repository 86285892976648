import {Box} from '@mui/material';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {
  flexRender,
} from '@tanstack/react-table';

import {HeaderItemWrapper, StyledHeaderTR, StyledSortIcon, StyledTH, StyledTableHead} from '../styles';
import {ITableHead} from '../types';

export const TableHead = ({
  table,
  isHorizontalScrolled,
  isVerticallyScrolled,
  isPaddingFirstColumn,
  isPaddingLastColumn,
  variant,
  firstColPaddingLeft,
  tableHeadPaddings,
  lastColPaddingLeft,
}: ITableHead) => {
  return (
    <StyledTableHead tableHeadPaddings={tableHeadPaddings}>
      {table.getHeaderGroups().map((headerGroup: any) => {
        const lastCellIndex = headerGroup.headers.length - 1;
        return (
          <StyledHeaderTR key={headerGroup.id} isVerticallyScrolled={isVerticallyScrolled}>
            {headerGroup.headers.map((header: any, headerIndex: number) => {
              const isFirstColumn = headerIndex === 0;
              const isFirstPaddingColumn = isFirstColumn && !!isPaddingFirstColumn;
              const isLastColumn = headerIndex === lastCellIndex && !!isPaddingLastColumn;
              const isSorted = header.column.getIsSorted();
              const calcPaddingLeft = isFirstPaddingColumn || isLastColumn ? (!isLastColumn && firstColPaddingLeft) || (lastColPaddingLeft || '36px') : '';
              return (
                <StyledTH
                  key={header.id}
                  colSpan={header.colSpan}
                  isFirstColumn={isFirstColumn}
                  isHorizontalScrolled={isHorizontalScrolled}
                  sx={{
                    minWidth: header.column.columnDef.minSize,
                    maxWidth: header.column.columnDef.maxSize,
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : (
                      <HeaderItemWrapper
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        <EllipsisTextTooltip
                          variant={variant ?? '14_18_700'}
                          ellipsisText={
                            flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                          sx={{
                            paddingLeft: calcPaddingLeft,
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </EllipsisTextTooltip>
                        {header.column.getCanSort() && (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              marginLeft: '5px',
                            }}>
                            <StyledSortIcon sorting={isSorted} />
                          </Box>
                        )}
                      </HeaderItemWrapper>
                    )
                  }
                </StyledTH>
              );
            })}
          </StyledHeaderTR>
        );
      })}
    </StyledTableHead>
  );
};
