import {Stack, Box, Typography, Divider} from '@mui/material';
import {State} from '@src/api';
import {RelationProfile} from '@src/api/relations';
import {GoogleAutocomplete} from '@src/components/Google/Autocomplete/GoogleAutocomplete';
import {useGeocoder} from '@src/components/Google/hooks';
import {useTranslate} from '@src/i18n/useTranslate';
import {MAX_ADDRESS_LENGTH} from '@src/shared/constants/formFields';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {getFullAddress} from '@src/shared/utils/getFullAddress';
import {normalizeString} from '@src/shared/utils/normalizeString';
import {useAddressForm, useContactRelations, useRelation} from '@src/store/relations/hooks';
import {relationsActions} from '@src/store/relations/slice';
import {FullAddress, PlaceResult} from '@src/types';
import {Nullable} from '@src/types/NullableModel';
import {Formik, FormikErrors} from 'formik';
import {isEqual} from 'lodash-es';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Button, InputControl} from 'ui-kit';

import {useValidationSchema} from './hooks';
import {StyledForm, sx} from './styles';
import {RelationContactsEditProps, SaveDataContacts} from './types';

export const RelationContactsEdit = ({handleCancelClick, fetching}: RelationContactsEditProps) => {
  const [value, setValue] = useState<null | FullAddress>(null);
  const [isOptionSelected, setIsOptionSelected] = useState(true);
  const resetHandlerRef = useRef({reset: () => {}});

  const validationSchema = useValidationSchema(isOptionSelected);
  const getGeocoderResult = useGeocoder();
  const {t, ready} = useTranslate('connections');
  const dispatch = useDispatch();
  const {id} = useParams();
  const {edit} = useAddressForm();
  const {mobile: isMobile, desktop: isDesktop} = useMQuery();
  const {relationId} = useContactRelations(id);
  const {relation} = useRelation(relationId);

  const currentStreetNumber = relation?.actualAddresses?.main?.split(' ')[0];
  const roundCoordinate = (coord: number) => Number(coord.toFixed(4));

  const initialValue = {
    state: relation?.actualAddresses?.state || '',
    city: relation?.actualAddresses?.city || '',
    postalCode: relation?.actualAddresses?.postalCode || '',
    address1: relation?.actualAddresses?.main || '',
    address2: relation?.actualAddresses?.additional || '',
    location: {
      googlePlaceId: relation?.actualAddresses?.location?.googlePlaceId || '',
      latitude: roundCoordinate(relation?.actualAddresses?.location?.latitude ?? 0),
      longitude: roundCoordinate(relation?.actualAddresses?.location?.longitude ?? 0),
    },
    streetNumber: currentStreetNumber,
  };

  const saveData = ({
    state,
    city,
    postalCode,
    address1,
    address2,
    location,
  }: Nullable<SaveDataContacts>) => {
    const data = {
      state: state as State || null,
      city: city || '',
      postalCode: postalCode || '',
      address1: address1 || '',
      address2: address2 || '',
      location: {
        googlePlaceId: location?.googlePlaceId,
        latitude: location?.latitude,
        longitude: location?.longitude,
      },
    };

    edit({relationId, data});
    if (relation && handleCancelClick) {
      const newRelation: RelationProfile = {
        ...relation,
        actualAddresses: {
          ...relation.actualAddresses,
          state,
          city,
          postalCode,
          main: address1,
          additional: address2,
        },
      };
      dispatch(relationsActions.setRelation({relation: newRelation}));
      handleCancelClick();
    }
  };

  const isSaveButtonDisabled = (values: typeof initialValue, errors: FormikErrors<typeof initialValue>) => {
    return isEqual(initialValue, values) || !!errors.address1 || !!errors.streetNumber || !values.address1;
  };

  if (!ready) return null;

  return (
    <Stack sx={!fetching ? sx.container : {}}>
      <Formik<typeof initialValue>
        validateOnChange
        validateOnBlur
        enableReinitialize
        onSubmit={(values) => {
          if (!isEqual(initialValue, values)) {
            const normalizedValues = {
              ...values,
              address1: values.address1,
              address2: normalizeString(values.address2),
              city: normalizeString(values.city),
              state: values.state as State || '',
            };

            saveData(normalizedValues);
          }
        }}
        initialValues={initialValue}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          errors,
          touched,
          setFieldValue,
          validateForm,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const handleAutocomplete = useCallback(
            (result: PlaceResult | null) => {
              const fullAddress = result ? getFullAddress(result) : null;
              if (fullAddress) {
                setValue(fullAddress);
                setFieldValue('address1', fullAddress.streetAddress || '');
                setFieldValue('city', fullAddress.city || '');
                setFieldValue('state', fullAddress.state || '');
                setFieldValue('postalCode', fullAddress.postalCode || '');
                setFieldValue('location', {
                  googlePlaceId: fullAddress.googlePlaceId || '',
                  latitude: fullAddress.latitude || 0,
                  longitude: fullAddress.longitude || 0,
                });
                setFieldValue('streetNumber', fullAddress.streetAddress?.split(' ')[0] || '');
                setIsOptionSelected(true);
              } else {
                resetHandlerRef.current.reset();
                setFieldValue('address1', '', true);
                setIsOptionSelected(false);
              }
            },
            [],
          );
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const handleAutocompleteRef = useRef(handleAutocomplete);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const handleChangeAddress1 = useCallback(
            (newValue: string) => {
              setFieldValue?.('address1', newValue);
            },
            [setFieldValue],
          );

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            const hasInitialValue = initialValue?.location.googlePlaceId || initialValue?.address1;
            const hasHandler = !!getGeocoderResult;

            if (hasInitialValue && hasHandler) {
              const request = {
                placeId: initialValue.location.googlePlaceId,
                address: initialValue.location.googlePlaceId
                  ? undefined
                  : initialValue.address1,

              };

              if (!request.placeId && !request.address) return;

              void getGeocoderResult(request)
                .then((result: any) => {
                  if (!result) return;

                  const placeResult = result as google.maps.places.PlaceResult;
                  handleAutocompleteRef.current(placeResult);
                  const fullAddressData = getFullAddress(placeResult);
                  setFieldValue('address1', fullAddressData.streetAddress || '');
                  setFieldValue('city', fullAddressData.city || '');
                  setFieldValue('state', fullAddressData.state || '');
                  setFieldValue('postalCode', fullAddressData.postalCode || '');
                  setFieldValue('location', {
                    googlePlaceId: fullAddressData.googlePlaceId || '',
                    latitude: roundCoordinate(fullAddressData.latitude || 0),
                    longitude: roundCoordinate(fullAddressData.longitude || 0),
                  });
                  setFieldValue('streetNumber', fullAddressData.streetAddress?.split(' ')[0] || '');
                })
                .catch((error: string) => {
                  console.error('Error calling geocoder:', error);
                });
            }
          }, [getGeocoderResult, initialValue?.address1, initialValue?.location.googlePlaceId]);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            void validateForm();
          }, [values.address1]);

          return (
            !fetching && (
              <StyledForm>
                <Stack sx={sx.content}>
                  {!isDesktop && <Divider sx={sx.divider} />}
                  <Typography
                    component='h4'
                    sx={sx.title}>
                    {t('ADDRESS')}
                  </Typography>
                  <GoogleAutocomplete
                    placeholder={t('ENTER_PATIENT_ADDRESS')}
                    value={value?.fullAddress}
                    label={t('ADDRESS')}
                    onChange={handleAutocomplete}
                    handleChangeAddress1={handleChangeAddress1}
                    setIsOptionSelected={setIsOptionSelected}
                    isOptionSelected={isOptionSelected}
                    error={errors.address1 || errors.streetNumber}
                  />
                  <InputControl
                    sx={sx.inputControl}
                    maxlength={MAX_ADDRESS_LENGTH}
                    disabled={!values.address1}
                    name="address2"
                    label={t('APARTMENT_SUIT_OR_UNIT')}
                    value={values.address2 || ''}
                    error={touched.address2 && errors.address2 ? errors.address2 : ''}
                    onChange={handleChange}
                  />
                </Stack>
                <Box sx={sx.buttonsContainer}>
                  <Button
                    fullWidth={isMobile}
                    variant="outlined"
                    onClick={handleCancelClick}
                  >
                    {t('CANCEL')}
                  </Button>
                  <Button
                    fullWidth={isMobile}
                    disabled={isSaveButtonDisabled(values, errors)}
                    type="submit"
                    variant="contained"
                  >
                    {t('SAVE')}
                  </Button>
                </Box>
              </StyledForm>
            )
          );
        }}
      </Formik>
    </Stack>
  );
};
