import {FC, useEffect, useState} from 'react';
import {SelectControl} from 'ui-kit';

import {FormSelectProps} from './type';
import {OptionValue} from 'ui-kit/lib/components/SelectControl/Select/types/OptionValue';
import {SelectChangeEvent} from '@mui/material';
import {TKeys} from '@src/i18n/useTranslate';
import i18n from '@src/i18n/i18n';

export const FormSelectControl: FC<FormSelectProps> = ({
  name,
  children,
  value,
  onChange,
  showErrorText = true,
  ...restProps
}) => {
  const [selectedValue, setSelectedValue] = useState<OptionValue<string | undefined>>();

  useEffect(() => {
    setSelectedValue(value
      ? { label: i18n.t(value as TKeys<'common'>), value }
      : undefined
    );
  }, [value]);

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { target: { value } } = event;

    const translatedValue = {
      label: i18n.t(value as TKeys<'common'>),
      value,
    };

    onChange(event);
    setSelectedValue(translatedValue);
  };

  return (
    <SelectControl
      name={name}
      value={selectedValue}
      onChange={handleSelectChange}
      {...restProps}
    >
      {children}
    </SelectControl>
  );
};
