import {Box, Typography} from '@mui/material';
import {Dialog, DialogActions, DialogTitle} from '@src/components/Dialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {useMQuery} from '@src/shared/hooks';
import {useSteps} from '@src/store/bmi/hooks';
import {paletteColors} from '@src/theme';
import {Button} from 'ui-kit';

import {DIALOG_CONTENT} from './constants';
import {sx} from './styles';

export const CalculatorModals = () => {
  const {t} = useTranslate('journal');
  const {step, setStep} = useSteps();
  const {mobile: isMobile} = useMQuery();

  const isSuccess = step === 'success';
  const dialogColor = `1px solid ${paletteColors.success[200]}`;

  const size = isMobile ? 'xs' : '402px';

  const closeModal = () => {
    setStep('init');
  };

  const dialogContent = DIALOG_CONTENT[step];

  if (!dialogContent) {
    return null;
  }

  const {title, content, icon: Icon, isSuccess: isSuccessModal} = dialogContent;

  return (
    <Dialog
      border={dialogColor}
      open={isSuccess}
      onClose={closeModal}
      size={size}
    >
      <DialogTitle
        onClose={closeModal}
        noContent={false}
        sx={{
          gap: 12,
          borderBottom: dialogColor,
        }}
      >
        <Icon/>
        {t(title)}
      </DialogTitle>
      <Box sx={{p: '36px 24px 66px 24px'}}>
        <Typography variant="14_18_500">
          {t(content)}
        </Typography>
      </Box>
      <DialogActions
        sx={sx.dialogActions}
        onClose={closeModal}
      >
        <Button
          variant="contained"
          color="inherit"
          sx={sx.successButton}
          onClick={closeModal}
        >
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
