import {DatePickerRange} from '@components';
import {SelectChangeEvent, Stack} from '@mui/material';
import {MultiSelect} from '@src/components/MultiSelect';
import {FiltersDialog as FiltersDialogContainer} from '@src/components/fragments/FiltersDialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {sx} from '@src/pages/Dynamics/Dynamics.style';
import {FC, useEffect, useState} from 'react';
import {MenuItem} from 'ui-kit';
import {FormSelectControl} from '@src/components/FormSelectControl';

import {getTimePeriod} from '../../Dynamic.helpers';
import {initialDates, initialFilters, TIME_PERIOD_OPTIONS} from '../../Dynamics.constant';

import {IFilterDialogProps} from './types';

export const FiltersDialog: FC<IFilterDialogProps> = ({
  filters,
  isOpen,
  onClose,
  testsOptions,
  onSetNewFilters,
  timePeriod,
  onSetTimePeriod,
}) => {
  const {t} = useTranslate('details');
  const [dialogFilters, setDialogFilters] = useState(filters);
  const [dialogTimePeriod, setDialogTimePeriod] = useState('');

  useEffect(() => {
    if (isOpen) {
      setDialogFilters(filters);
      setDialogTimePeriod(timePeriod);
    }
  }, [isOpen]);

  const changeDate = (name: string) => (date: Date | null) => {
    setDialogFilters((prevState) => ({...prevState, [name]: date}));
    setDialogTimePeriod('Custom');
  };

  const clearDate = () => {
    setDialogFilters((prevState) => ({...prevState, ...initialDates}));
  };

  const changeTimePeriod = (event: SelectChangeEvent<string>) => {
    const {value} = event.target;
    setDialogTimePeriod(value);

    const dates = getTimePeriod(value);
    setDialogFilters((prev) => ({...prev, ...dates}));
  };

  const changeTestCodes = (values: string[]) => {
    setDialogFilters((prev) => ({...prev, testCodes: values}));
  };

  const handleClear = () => {
    setDialogFilters(initialFilters);
    setDialogTimePeriod('');
  };

  const handleApply = () => {
    onClose();
    onSetNewFilters(dialogFilters);
    onSetTimePeriod(dialogTimePeriod);
  };

  return (
    <FiltersDialogContainer
      isOpen={isOpen}
      title={t('FILTERS')}
      onClose={onClose}
      onApply={handleApply}
      onClear={handleClear}
    >
      <Stack gap={28} sx={sx.mobileDialogContainer}>
        <FormSelectControl
          value={dialogTimePeriod}
          onChange={changeTimePeriod}
          label={t('TIME_PERIOD')}
          placeholder={t('SELECT')}
          ignoreLabelHeight
        >
          {TIME_PERIOD_OPTIONS.map((option) => (
            <MenuItem key={option.name} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </FormSelectControl>
        <DatePickerRange
          sx={{mb: '20px !important'}}
          isIconVisible={false}
          variant="outlined"
          label={t('DATE_RANGE')}
          startDate={dialogFilters.start}
          endDate={dialogFilters.end}
          startAdornmentName="Date"
          setStartDate={changeDate('start')}
          setEndDate={changeDate('end')}
          clearDate={clearDate}
          isLabelOutside={true}
          labelTop
        />
        <MultiSelect
          value={dialogFilters.testCodes}
          label={t('TESTS')}
          placeholder={t('SELECT')}
          options={testsOptions}
          onChange={changeTestCodes}
          inputsSx={sx.testsSelect}
          ignoreLabelHeight
        />
      </Stack>
    </FiltersDialogContainer>
  );
};
