import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  requestAvatar: (t) => ({
    width: {
      xs: 22,
      sm: 40,
    },
    height: {
      xs: 22,
      sm: 40,
    },
    marginRight: 10,
    backgroundColor: {
      xs: 'transparent',
      sm: t.palette.secondary[400],
    },
    color: {
      xs: 'initial',
      sm: t.palette.common.white,
    },
  }),
  requestType: (t) => ({
    minHeight: 20,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [t.breakpoints.only('xs')]: {
      typography: '12_20_600',
      minHeight: 0,
    },
  }),
  personName: (t) => ({
    [t.breakpoints.only('xs')]: {
      typography: '14_20_700',
    },
  }),
  trashButton: {
    p: 0,
    mt: -6,
  },
  emptyRequests: {
    color: t => t.palette.grey[500],
    p: {
      xs: 18,
      sm: '2px 24px',
    },
    typography: {
      xs: '12_16_500',
      sm: '14_18_500',
    },
  },
  boxContainer: {
    display: 'flex',
    overflow: 'hidden',
  },
  widthLimitContainer: {
    display: 'grid',
    minWidth: '10%',
    gap: {
      xs: 6,
      sm: 3,
    },
  },
  flexContainer: {
    alignItems: 'end',
    justifyContent: 'space-between',
  },
  date: (t) => ({
    color: t => t.palette.grey[500],
    [t.breakpoints.only('xs')]: {
      typography: '12_16_500',
    },
  }),
  wrapper: {
    pb: {
      xs: 18,
      sm: 40,
      lg: 38,
    },
    overflow: 'auto',
  },
};
