import {Box, Stack} from '@mui/material';
import {PdfViewer} from '@src/components/PdfViewer';
import {WBox} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {Icons} from '@src/shared/assets/icons';
import {useMQuery} from '@src/shared/hooks';
import {openBase64NewTab} from '@src/shared/utils/pdfHelpers';
import {FC, useCallback} from 'react';
import {Button} from 'ui-kit';

import {sx} from './styled';
import {IPdfResultsViewProps} from './types';

export const PdfResultsView: FC<IPdfResultsViewProps> = ({
  resultData,
}) => {
  const {t} = useTranslate('results');
  const {mobile} = useMQuery();

  const clickHandler = useCallback(() => {
    openBase64NewTab(resultData.data);
  }, [resultData.data]);

  return (
    <WBox sx={sx.mainBlock}>
      <Stack gap={36}>
        <Box sx={sx.topblock}>
          <Button
            sx={sx.saveBtn}
            variant="contained"
            color="primary"
            href={ROUTERS_PATH.OVERVIEW}
            size={mobile ? 'md' : 'lg'}
          >
            {t('SIGN_IN')}
          </Button>
          <Button
            variant="text"
            color="secondary"
            startIcon={<Icons.Download.X18 />}
            onClick={clickHandler}
          >{t('VIEW_ALL_PAGES')}</Button>
        </Box>
        <PdfViewer data={resultData.data} sx={sx.viewer} />
      </Stack>
    </WBox>
  );
};
