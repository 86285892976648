import {TKeys} from '@src/i18n/useTranslate';
import {ReactComponent as ErrorIcon} from '@src/shared/assets/icons/error.svg';
import {ReactComponent as SuccessIcon} from '@src/shared/assets/icons/success.svg';
import {FC, SVGProps} from 'react';

export interface DialogContent {
  title: TKeys<'journal'>
  content: TKeys<'journal'>
  icon: FC<SVGProps<SVGSVGElement>>
  isSuccess: boolean
}

export const DIALOG_CONTENT: Record<string, DialogContent> = {
  success: {
    title: 'SUCCESS',
    content: 'RESULT_HAS_SAVED',
    icon: SuccessIcon,
    isSuccess: true,
  },
};
