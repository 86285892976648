import {SxProps, Theme} from '@mui/material';

// TODO заменить цвета с # на цвета из ui-kit (данные цвета пока не добавлены)
export const sx: Partial<Record<string, SxProps<Theme>>> = {
  contentContainer: (t) => ({
    border: '1px solid',
    borderColor: t.palette.secondary[400],
    backgroundColor: t.palette.secondary[100],
    p: {
      xs: '6px 6px 12px 12px',
      sm: '6px 6px 18px 18px',
    },
    boxSizing: 'border-box',
    flexDirection: 'initial',
    justifyContent: 'space-between',
    minWidth: {xs: 270, sm: 0},
    height: 'auto',
  }),
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  delete: {
    mr: -6,
    mt: -1,
    cursor: 'pointer',
    height: 42,
    width: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    m: 0,
  },
  expired: {
    background: '#F5F2F4',
    color: '#997A8D',
    padding: '4px 8px 4px 8px',
  },
  active: {
    padding: '4px 8px 4px 8px',
    background: '#E6F8F7',
    color: '#05B7AC',
  },
  cardTitle: {
    mr: {
      xs: 40,
      sm: 46,
      lg: 60,
    },
    '&:first-child': {
      mt: {
        xs: 0,
        sm: 6,
      },
    },
    cursor: 'auto',
  },
  dateType: {
    mr: 12,
  },
  titleBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rowInfo: {
    mb: {
      xs: 8,
      sm: 9,
    },
  },
};
