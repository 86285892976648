import {Stack, Typography, Box, ListItemText} from '@mui/material';
import {StateType, HealthcareInsurancePolicyChangeRequest} from '@src/api/insurances';
import {ListWrapper, PageHeader as PageTitle, RowInformation} from '@src/components';
import Grid from '@src/components/Grid';
import {PulseLoader} from '@src/components/PulseLoader';
import {WBox} from '@src/components/WhiteBox';
import {DateControl} from '@src/components/form/DateControl';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {POSTAL_CODE_LENGTH} from '@src/pages/Connections/constants';
import {useValidationSchema} from '@src/pages/Insurance/utils/useValidationSchema';
import {ROUTERS_PATH} from '@src/routers';
import {MAX_ADDRESS_LENGTH, MAX_CITY_LENGTH, MAX_INPUT_LENGTH, MAX_SSN_LENGTH} from '@src/shared/constants/formFields';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {dateToFormat} from '@src/shared/utils';
import {getRowInformationTypography} from '@src/shared/utils/getRowInformationTypography';
import {validateValue} from '@src/shared/utils/validateValue';
import {useAccountProfile} from '@src/store/account/hooks';
import {
  useInsurances,
} from '@src/store/insurance/hooks';
import {createEditDeleteInsuranceThunk} from '@src/store/insurance/slice';
import {RelationshipTypes} from '@src/types';
import {Nullable} from '@src/types/NullableModel';
import {useAppDispatch} from '@store';
import {Formik, FormikHelpers, FormikProps, FormikTouched} from 'formik';
import {isEqual} from 'lodash-es';
import {useCallback} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {PhoneInputControl, Button, InputControl, MenuItem} from 'ui-kit';
import {FormSelectControl} from '@src/components/FormSelectControl';

import {
  birthSexSelectOptions,
  relationTypesSelectOptions,
  statesSelectOptions,
} from '../constants';
import {getColSpacing} from '../utils/getColSpacing';
import {getListWrapperPaddings} from '../utils/getListWrapperPaddings';
import {getRowInfoSpaceBetween} from '../utils/getRowInfoSpaceBetween';

import {touchedData} from './constants';
import {StyledForm, sx} from './styles';
import {EditDataHealthPolicies} from './types';

export const EditInsurance = () => {
  const {t, ready} = useTranslate('insurance');
  const dispatch = useAppDispatch();
  const params = useParams();

  const {mobile: isMobile, desktop: isDesktop} = useMQuery();
  const {accountProfile, fetching: isAccountFetching} = useAccountProfile();
  const {healthcareInsurancePolicies, fetching: isInsurancesLoading} = useInsurances();
  const navigate = useNavigate();
  const insurance = healthcareInsurancePolicies?.find((insurance) => insurance.id === params.id);
  const isPrimaryInsurance = insurance?.primary;
  const validationSchema = useValidationSchema(insurance);

  const isLoading = isAccountFetching || isInsurancesLoading;

  const editInsurance = useCallback(
    (data: HealthcareInsurancePolicyChangeRequest) => {
      dispatch(createEditDeleteInsuranceThunk(data))
        .unwrap()
        .then(() => {
          navigate(ROUTERS_PATH.INSURANCE);
        }).catch(() => {});
    },
    [dispatch],
  );

  const initialValue = {
    relationship: insurance?.guarantor?.relationType || null,
    birthDate: insurance?.guarantor?.birthDate || null,
    birthSex: insurance?.guarantor?.birthSex || null,
    firstName: insurance?.guarantor?.firstName || null,
    lastName: insurance?.guarantor?.lastName || null,
    middleName: insurance?.guarantor?.middleName || null,
    ssnTail: insurance?.guarantor?.ssnTail || null,
    state: insurance?.guarantor?.state ? StateType[insurance?.guarantor?.state] : null,
    city: insurance?.guarantor?.city || null,
    postalCode: insurance?.guarantor?.postalCode || null,
    address1: insurance?.guarantor?.main || null,
    address2: insurance?.guarantor?.additional || null,
    phone: insurance?.guarantor?.phone || null,
    endDate: insurance?.endDate || null,
    id: insurance?.id || null,
  };

  const saveData = (values: Nullable<EditDataHealthPolicies>) => {
    if (isEqual(initialValue, values)) {
      return;
    }

    const {
      relationship,
      birthDate,
      birthSex,
      firstName,
      lastName,
      middleName,
      ssnTail,
      state,
      city,
      postalCode,
      address1,
      address2,
      phone,
      endDate,
      id,
    } = values;

    const data = [
      {
        endDate: endDate
          ? {
            value: endDate || '',
          }
          : null,
        guarantor: {
          value: {
            address1: {
              value: address1 || '',
            },
            address2: {
              value: address2 || '',
            },
            birthDate: {
              value: birthDate || undefined,
            },
            birthSex: {
              value: birthSex || undefined,
            },
            city: {
              value: city || undefined,
            },
            firstName: {
              value: firstName || undefined,
            },
            lastName: {
              value: lastName || undefined,
            },
            middleName: {
              value: middleName || '',
            },
            phone: {
              value: phone || '',
            },
            postalCode: {
              value: postalCode || undefined,
            },
            relationType: (!relationship || relationship === RelationshipTypes.SELF)
              ? {
                value: null,
              }
              : {
                value: relationship,
              },
            ssnTail: {
              value: ssnTail || '',
            },
            state: {
              value: state || null,
            },
          },
        },
        id: id || '',
      },
    ];
    editInsurance({
      deletedHealthcareInsurancePolicyIds: [],
      healthcareInsurancePoliciesCreate: [],
      healthcareInsurancePoliciesEdit: data || undefined,
    });
  };

  // eslint-disable-next-line
  const setGuarantorFieldsBySelf = (setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
    setFieldValue('firstName', accountProfile?.contact?.firstName);
    setFieldValue('lastName', accountProfile?.contact?.lastName);
    setFieldValue('middleName', accountProfile?.contact?.middleName);
    setFieldValue('birthDate', accountProfile?.contact?.birthDate);
    setFieldValue('birthSex', accountProfile?.contact?.birthSex);
    setFieldValue('ssnTail', accountProfile?.contact?.ssnTail);
    setFieldValue('state', accountProfile?.actualAddresses?.state);
    setFieldValue('city', accountProfile?.actualAddresses?.city);
    setFieldValue('address1', accountProfile?.actualAddresses?.main);
    setFieldValue('address2', accountProfile?.actualAddresses?.additional);
    setFieldValue('postalCode', accountProfile?.actualAddresses?.postalCode);
    setFieldValue('phone', accountProfile?.phones ? accountProfile?.phones[0].phone : '');
  };

  const handleCancelClick = (resetForm: FormikHelpers<typeof initialValue>['resetForm']) => {
    navigate(ROUTERS_PATH.INSURANCE);
    resetForm();
  };

  const handleFormSubmit = (
    handleSubmit: FormikProps<typeof initialValue>['handleSubmit'],
    setTouched: (touched: FormikTouched<typeof initialValue>, shouldValidate?: boolean) => void,
  ) => {
    handleSubmit();
    setTouched(touchedData);
  };

  const {rowInfoNameTypography, rowInfoValueTypography} = getRowInformationTypography(isMobile);
  const spaceBetween = getRowInfoSpaceBetween(isMobile);
  const listWrapperPaddings = getListWrapperPaddings(isMobile);
  const colSpacing = getColSpacing(isDesktop);

  if (isLoading || !ready) {
    return (
      <>
        <Stack sx={sx.mainTitleWrapper}>
          <PageTitle>{ready ? t('INSURANCE') : ''}</PageTitle>
        </Stack>
        <WBox sx={{...sx.whiteBox, justifyContent: 'center', alignItems: 'center', flex: 1}}>
          <PulseLoader loading />
        </WBox>
      </>
    );
  }

  return (
    <Formik<typeof initialValue>
      enableReinitialize
      onSubmit={saveData}
      initialValues={initialValue}
      validationSchema={validationSchema}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        setFieldValue,
        errors,
        touched,
        setFieldTouched,
        setTouched,
        resetForm,
      }) => {
        return (
          <StyledForm>
            <Stack sx={sx.mainTitleWrapper}>
              <PageTitle>{t('INSURANCE')}</PageTitle>
            </Stack>
            <WBox sx={sx.whiteBox}>
              <Stack sx={sx.mainContainer}>
                <Stack gap={isDesktop ? 18 : 24}>
                  <Typography variant={isMobile ? '22_30_500' : '24_30_500'}>
                    {isPrimaryInsurance ? t('PRIMARY_INSURANCE') : t('SECONDARY_INSURANCE')}
                  </Typography>
                  <ListWrapper padding={listWrapperPaddings} noLast={{xs: 0}}>
                    <RowInformation
                      variant={rowInfoNameTypography}
                      valueTypographyVariant={rowInfoValueTypography}
                      noWrap={!isDesktop}
                      tooltip={isDesktop}
                      name={t('CARRIER')}
                      value={insurance?.insurancePlan?.carrier?.name}
                      noMargin
                      spaceBetween={spaceBetween}
                    />
                    <RowInformation
                      variant={rowInfoNameTypography}
                      valueTypographyVariant={rowInfoValueTypography}
                      noWrap={!isDesktop}
                      tooltip={isDesktop}
                      name={t('PLAN')}
                      value={insurance?.insurancePlan?.name}
                      noMargin
                      spaceBetween={spaceBetween}
                    />
                    <RowInformation
                      variant={rowInfoNameTypography}
                      valueTypographyVariant={rowInfoValueTypography}
                      noWrap={!isDesktop}
                      tooltip={isDesktop}
                      name={t('POLICY_ID')}
                      value={insurance?.number}
                      noMargin
                      spaceBetween={spaceBetween}
                    />
                    <Grid container mt={{xs: 10, sm: 4}} columnSpacing={colSpacing}>
                      <Grid
                        xl={4}
                        sm={6}
                        xs={12}>
                        <DateControl
                          labelTop
                          reverseYears
                          disabledPast
                          onlyFutureYears
                          name="endDate"
                          helperText={t('OPTIONAL')}
                          placeholder={t('MM_DD_YYYY')}
                          value={dateToFormat('P', values.endDate) || ''}
                          onChange={handleChange}
                          label={t('END_DATE')}
                          error={errors.endDate}
                          optional
                        />
                      </Grid>
                    </Grid>
                  </ListWrapper>
                </Stack>
                <Stack gap={24}>
                  <Stack flexGrow={1} gap={16}>
                    <Box sx={{display: 'flex', gap: 24}}>
                      <Typography component="h4" variant={isMobile ? '18_24_700' : '20_24_700' } sx={sx.title}>
                        {t('GUARANTOR')}
                      </Typography>
                    </Box>
                    <Grid container spacing={24} columnSpacing={colSpacing}>
                      <Grid
                        xl={4}
                        sm={6}
                        xs={12}>
                        <FormSelectControl
                          name="relationship"
                          placeholder={t('SELECT')}
                          value={values.relationship || ''}
                          onChange={(e: any) => {
                            handleChange(e);
                            if (e.target.value === RelationshipTypes.SELF) {
                              setGuarantorFieldsBySelf(setFieldValue);
                            }
                          }}
                          error={
                            touched?.relationship && !values.relationship ? errors.relationship : ''
                          }
                          label={t('RELATIONSHIP')}
                        >
                          {relationTypesSelectOptions.map(option => (
                            <MenuItem key={option.name} value={option.name}>
                              {t(option.name as TKeys<'common'>)}
                            </MenuItem>
                          ))}
                        </FormSelectControl>
                      </Grid>
                    </Grid>
                  </Stack>
                  <Stack gap={24}>
                    <Stack flexGrow={1}>
                      <Grid container spacing={24} columnSpacing={colSpacing}>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <InputControl
                            name="firstName"
                            label={t('FIRST_NAME')}
                            value={values.firstName || ''}
                            error={touched?.firstName ? errors.firstName : ''}
                            onChange={handleChange}
                            maxlength={MAX_INPUT_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <InputControl
                            name="lastName"
                            label={t('LAST_NAME')}
                            value={values.lastName || ''}
                            error={touched?.lastName ? errors.lastName : ''}
                            onChange={handleChange}
                            maxlength={MAX_INPUT_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <InputControl
                            name="middleName"
                            label={t('MIDDLE_NAME')}
                            value={values.middleName || ''}
                            optional
                            error={touched?.middleName ? errors.middleName : ''}
                            onChange={handleChange}
                            maxlength={MAX_INPUT_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <DateControl
                            labelTop
                            name="birthDate"
                            disabledFuture
                            value={dateToFormat('P', values.birthDate) || ''}
                            onChange={handleChange}
                            onTouched={setFieldTouched}
                            label={t('DATE_OF_BIRTH')}
                            placeholder={t('MM_DD_YYYY')}
                            hideTip={false}
                            error={touched?.birthDate && !values?.birthDate ? errors.birthDate : ''}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <FormSelectControl
                            name="birthSex"
                            placeholder={t('SELECT')}
                            value={values.birthSex || ''}
                            onChange={handleChange}
                            error={touched?.birthSex && !values.birthSex ? errors.birthSex : ''}
                            label={t('BIRTH_SEX')}
                          >
                            {birthSexSelectOptions.map(option => (
                              <MenuItem key={option.name} value={option.name}>
                                {t(option.name as TKeys<'common'>)}
                              </MenuItem>
                            ))}
                          </FormSelectControl>
                        </Grid>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <InputControl
                            name="ssnTail"
                            label={t('SSN_LAST_4')}
                            optional
                            error={touched?.ssnTail ? errors.ssnTail : ''}
                            value={values.ssnTail || ''}
                            onChange={handleChange}
                            maxlength={MAX_SSN_LENGTH}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack flexGrow={1}>
                      <Grid container spacing={24} columnSpacing={colSpacing}>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <FormSelectControl
                            name="state"
                            value={values.state || ''}
                            placeholder={t('SELECT')}
                            onChange={handleChange}
                            error={touched?.state && !values.state ? errors.state : ''}
                            label={t('STATE')}
                          >
                            {statesSelectOptions.map(option => (
                              <MenuItem key={option.name} value={option.name}>
                               {t(option.name as TKeys<'common'>)}
                              </MenuItem>
                            ))}
                          </FormSelectControl>
                        </Grid>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <InputControl
                            name="city"
                            label={t('CITY')}
                            value={values.city || ''}
                            error={touched?.city ? errors.city : ''}
                            onChange={handleChange}
                            maxlength={MAX_CITY_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <InputControl
                            name="postalCode"
                            label={t('ZIP_CODE')}
                            value={validateValue(values.postalCode || '')}
                            error={touched?.postalCode ? errors.postalCode : ''}
                            onChange={handleChange}
                            maxlength={POSTAL_CODE_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <InputControl
                            name="address1"
                            label={t('ADDRESS_1')}
                            error={touched?.address1 ? errors.address1 : ''}
                            value={values.address1 || ''}
                            onChange={handleChange}
                            maxlength={MAX_ADDRESS_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <InputControl
                            name="address2"
                            label={t('ADDRESS_2')}
                            error={touched?.address2 ? errors.address2 : ''}
                            optional
                            value={values.address2 || ''}
                            onChange={handleChange}
                            maxlength={MAX_ADDRESS_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <PhoneInputControl
                            optional
                            name="phone"
                            label={t('PHONE')}
                            error={touched?.phone ? errors.phone : ''}
                            placeholder={t('ENTER_A_PHONE')}
                            value={values.phone || ''}
                            onChange={handleChange}
                            sx={sx.phoneInput}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Stack sx={sx.buttonsWrapper}>
                <Button
                  sx={sx.button}
                  variant="outlined"
                  color="primary"
                  onClick={() => handleCancelClick(resetForm)}
                >
                  {t('CANCEL')}
                </Button>
                <Button
                  disabled={isEqual(initialValue, values)}
                  sx={sx.button}
                  variant="contained"
                  color="primary"
                  onClick={() => handleFormSubmit(handleSubmit, setTouched)}
                >
                  {t('SAVE')}
                </Button>
              </Stack>
            </WBox>
          </StyledForm>
        );
      }}
    </Formik>
  );
};
