import {
  Stack,
  Typography,
  Avatar,
  Box,
} from '@mui/material';
import {AccountStatus, RelationContactSearchResult} from '@src/api/relations';
import {CircleStatus, Tooltip} from '@src/components';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {useTranslate} from '@src/i18n/useTranslate';
import {useMQuery, useSidebarClassObserver} from '@src/shared/hooks';
import {useWindowWidth} from '@src/shared/hooks/useWindowWidth';
import {Table as NewTable} from '@src/shared/ui/Table/Table';
import {getUserInitials} from '@src/shared/utils';
import {getName} from '@src/shared/utils/getName';
import {createColumnHelper} from '@tanstack/react-table';
import {useMemo} from 'react';

import {AddRelationTableAction} from './AddRelationTableAction';
import {ADAPTIVE_COL_COUNT, COLORS, FIRST_COL_SIZE, LAST_COL_SIZE, TOTAL_TABLE_PADDINGS} from './constant';
import {calcMinColWidth, getSidebarOffset} from './helpers';
import {sx} from './styles';
import {EAccountStatusName, IAddRelationTableProps} from './types';

export const AddRelationTable = ({
  relationContacts,
  relationType,
}: IAddRelationTableProps) => {
  const {t} = useTranslate('connections');
  const {lg, xl, xxl} = useMQuery();
  const columnHelper = createColumnHelper<RelationContactSearchResult>();

  const windowWidth = useWindowWidth();
  const {isSidebarOpen} = useSidebarClassObserver();

  const maxAvailableWidth = lg ? 1535 : 5920;

  const sidebarOffset = getSidebarOffset(isSidebarOpen, xxl);

  const availableWidth = Math.min(windowWidth, maxAvailableWidth) - TOTAL_TABLE_PADDINGS - sidebarOffset;

  const minColumnWidth = calcMinColWidth(lg, xl);

  const physicianColumnWidth = Math.max(Math.floor(availableWidth / ADAPTIVE_COL_COUNT), minColumnWidth);

  const columns = useMemo(() => [
    columnHelper.accessor('firstName', {
      id: 'firstName',
      header: t('NAME'),
      minSize: FIRST_COL_SIZE,
      maxSize: FIRST_COL_SIZE,
      enableSorting: false,
      cell: ({row}) => {
        const accountStatus = row.original.accountStatus;
        return (
          <Stack sx={sx.presonColumnWrapper}>
            <Tooltip title={`Account status - ${EAccountStatusName[accountStatus]}`}>
              <Box sx={sx.flag}>
                <CircleStatus color={COLORS[AccountStatus[accountStatus]].color} />
              </Box>
            </Tooltip>
            <Avatar sx={sx.requestAvatar} src={row.original.accountAvatarUrl}>
              <Typography variant="14_20_700">
                {getUserInitials({
                  firstName: row.original.firstName,
                  lastName: row.original.lastName,
                  middleName: row.original.middleName,
                })}
              </Typography>
            </Avatar>
            <Stack sx={sx.personNameWrapper}>
              <EllipsisTextTooltip
                variant="14_18_700"
                sx={sx.personName}
                ellipsisText={getName({firstName: row.original.firstName, lastName: row.original.lastName})}
              >
                {getName({
                  firstName: row.original.firstName,
                  lastName: row.original.lastName,
                })}
              </EllipsisTextTooltip>
              <Typography variant="14_18_400">{row.original.birthDateS}</Typography>
            </Stack>
          </Stack>
        );
      },
    }),
    columnHelper.accessor('phone', {
      id: 'phone',
      header: t('PHONE'),
      minSize: physicianColumnWidth,
      maxSize: physicianColumnWidth,
      enableSorting: false,
      cell: ({getValue}) => (
        <Box sx={{wordBreak: 'break-word'}}>{getValue()}</Box>
      ),
    }),
    columnHelper.accessor('email', {
      id: 'email',
      header: t('EMAIL'),
      minSize: physicianColumnWidth,
      maxSize: physicianColumnWidth,
      enableSorting: false,
      cell: ({getValue}) => (
        <EllipsisTextTooltip variant="14_18_400" ellipsisText={getValue()}>
          {getValue()}
        </EllipsisTextTooltip>
      ),
    }),
    columnHelper.accessor('ssnTail', {
      id: 'ssnTail',
      header: t('SSN_LAST_4_DIGITS'),
      minSize: physicianColumnWidth,
      maxSize: physicianColumnWidth,
      enableSorting: false,
      cell: ({getValue}) => <Box>{getValue()}</Box>,
    }),
    columnHelper.accessor('policyNumber', {
      id: 'policyNumber',
      header: t('POLICY_ID'),
      minSize: physicianColumnWidth,
      maxSize: physicianColumnWidth,
      enableSorting: false,
      cell: ({getValue}) => <Box>{getValue()}</Box>,
    }),
    columnHelper.display({
      id: 'menu',
      header: t('ACTION'),
      enableSorting: false,
      minSize: LAST_COL_SIZE,
      maxSize: LAST_COL_SIZE,
      cell: ({row}) => (
        <AddRelationTableAction
          id={row.original.id}
          relationError={row.original.relationError}
          relationshipType={relationType || null}
          newContactBirthDate={row.original.birthDateS}
        />
      ),
    }),
  ], [columnHelper, relationType, xl, t, physicianColumnWidth]);

  if (relationContacts.length < 1) {
    return (
      <Typography
        variant='14_18_500'
        sx={sx.patientNotFound}
      >
        {t('NO_SUITABLE_RESULTS')}
      </Typography>
    );
  };

  return (
    <NewTable
      firstColPaddingLeft={'48px'}
      lastColPaddingLeft={'30px'}
      tableHeadPaddings={'6px 18px'}
      columns={columns}
      data={relationContacts}
      isPaddingFirstColumn={true}
      isPaddingLastColumn={true}
      customTdPaddings={'6px 18px'}
    />
  );
};
