import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  wrapper: {
    height: 'calc(100vh - 132px)',
    gap: 24,
  },
  container: {
    flexGrow: 1,
    p: 24,
    minWidth: 0,
  },
  contentContainer: (t) => ({
    flexDirection: 'initial',
    overflow: 'auto',
    border: '1px solid',
    borderColor: t.palette.secondary[300],
  }),
  avatar: (t) => ({
    width: 24,
    height: 24,
    marginRight: 6,
    backgroundColor: t.palette.secondary[600],
    color: t.palette.common.white,
  }),
  tab: {
    flex: 1,
    minWidth: {lg: 212, xl: 250},
    padding: '6px 20px 12px 20px',
  },
  tabs: {
    typography: '16_24_500',
    padding: '6px 90px 12px 90px',
  },
  tabsContainer: {
    display: 'flex',
    alignSelf: 'flex-end',
    '.MuiTabs-indicator': {
      height: '1.6px',
    },
  },
  breadCrumbs: (t) => ({
    flexDirection: 'row',
    gap: 4,
    alignItems: 'center',
    color: t.palette.grey[800],
    'a, a:visited': {
      color: t.palette.grey[800],
    },
  }),
  breakRelationBth: {
    p: 0,
    typography: '18_24_500',
    minWidth: '118px !important',
  },
  breakRelationContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    ml: 60,
    minWidth: '10%',
  },
  avatarBoxContainer: {
    display: 'flex',
    alignItems: 'center',
    mr: 24,
    minWidth: '10%',
  },
  mainTabsContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    left: '1px',
    top: '1px',
  },
  changeAccessBth: {
    p: 0,
    minWidth: '124px !important',
  },
  boxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 30,
  },
  outletContainer: {
    flexGrow: 1,
    height: 'fit-content',
    overflow: 'hidden',
  },
};
