import {RelationContactSimilarErrorType} from '@src/api/relations';

export const RESET_FORM_DATA = {
  relationShipType: null,
  firstName: null,
  lastName: null,
  middleName: null,
  birthDate: null,
  birthSex: null,
  gender: null,
  race: null,
  ethnicity: null,
  sexualOrientation: null,
  phone: null,
  email: null,
  whichPrimaryInsuranceUse: 'patients',
  owner: null,
  insuranceCarrier: null,
  insurancePlan: null,
  policyId: null,
  endDate: null,
};

export const SIMILAR_STRATEGY_ERROR = RelationContactSimilarErrorType.CONTACT_SIMILAR_STRATEGY;
export const UNIQUE_CONFIRMED_PHONE_ERROR = RelationContactSimilarErrorType.UNIQUE_CONFIRMED_PHONE;
export const UNIQUE_UNCONFIRMED_PHONE_ERROR = RelationContactSimilarErrorType.UNIQUE_UNCONFIRMED_PHONE;
