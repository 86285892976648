import {DateTime} from 'luxon';
import {FC} from 'react';
import {ISODateString} from '@src/types';
export enum DateTimeFormat {
  USDate = 'MM-dd-yyyy',
  USDateSlashed = 'MM/dd/yyyy',
  ISODate = 'yyyy-MM-dd',
  ISODateWithHours = 'yyyy/MM/dd HH'
}
export const US_DATE_PLACEHOLDER_SLASHED = '00/00/0000';

export const DEFAULT_DATE_FORMAT = DateTimeFormat.USDateSlashed;
export const formatDate = (
  date: ISODateString | null | undefined,
  format: DateTimeFormat = DEFAULT_DATE_FORMAT,
) => {
  if (!date) {
    return date;
  }
  const luxonDate = DateTime.fromISO(date);
  if (luxonDate.isValid) {
    return luxonDate.toFormat(format);
  }
  return date;
};
export const DateFormat: FC<{
  children: string | undefined | null
  format?: DateTimeFormat
}> = ({children, format = DEFAULT_DATE_FORMAT}) => {
  const date = formatDate(children, format);
  return <>{date}</>;
};
