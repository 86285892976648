import {AccountStatus} from '@src/api/relations';
import {TableHeaderItem} from '@src/components/Table/types';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {useTranslate} from 'i18n/useTranslate';

export const useHeader = (filterResultByDate: (key: string) => () => void, reportedPosition?: boolean, collectedPosition?: boolean) => {
  const {t} = useTranslate('results');
  const {mobile} = useMQuery();
  const HEADER_LIST: TableHeaderItem[] = [
    {
      name: t('REPORTED'),
      action: {action: filterResultByDate('reported'), position: 'end', sorted: reportedPosition},
    },
    ...(mobile
      ? [
        {
          name: t('PHYSICIAN'),
        },
      ] as TableHeaderItem[]
      : [
        {
          name: t('COLLECTED'),
          action: {action: filterResultByDate('collected'), position: 'end', sorted: collectedPosition},
        },
        {
          name: t('PHYSICIAN'),
        },
        {
          name: t('FLAG'),
        },
      ] as TableHeaderItem[]),
  ];
  return {HEADER_LIST};
};

export enum EFlagColor {
  'NEW' = '#0E75BF',
  'INVITE' = '#FAB41C',
  'ACTIVE' = '#05B7AC',
  'BLOCKED' = '#858D9C',
}

export enum EBackgroundColor {
  'NEW' = '#E7F1F9',
  'ACTIVE' = '#E6F8F7',
  'INVITE' = '#FEF7E8',
  'BLOCKED' = '#F3F4F6',
}

export const MOBILE_COLORS = {
  [AccountStatus.NEW]: {
    color: EFlagColor.NEW,
    backgroundColor: EBackgroundColor.NEW,
  },
  [AccountStatus.ACTIVE]: {
    color: EFlagColor.ACTIVE,
    backgroundColor: EBackgroundColor.ACTIVE,
  },
  [AccountStatus.INVITE]: {
    color: EFlagColor.INVITE,
    backgroundColor: EBackgroundColor.INVITE,
  },
  [AccountStatus.BLOCKED]: {
    color: EFlagColor.BLOCKED,
    backgroundColor: EBackgroundColor.BLOCKED,
  },
};

export const COLORS = {
  [AccountStatus.NEW]: {
    color: EFlagColor.NEW,
  },
  [AccountStatus.ACTIVE]: {
    color: EFlagColor.ACTIVE,
  },
  [AccountStatus.INVITE]: {
    color: EFlagColor.INVITE,
  },
  [AccountStatus.BLOCKED]: {
    color: EFlagColor.BLOCKED,
  },
};

export const FIRST_COL_SIZE = 320;
export const OTHER_INDENT = 222;
export const SUM_OF_TOTAL_PADDINGS = 158;
export const LAST_COL_SIZE = 228;
export const ADAPTIVE_COL_COUNT = 4;
export const TOTAL_TABLE_PADDINGS = SUM_OF_TOTAL_PADDINGS + FIRST_COL_SIZE + OTHER_INDENT;
